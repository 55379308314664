new Vue({
	el: '#cash-invoice',

	data: {
		newInvoice: {
			id: '',
			vendor_id : '',
			invoice_no: '',
			deposit: '',
			cash_amount: '',
			accountant_remark: '',
			type: 'c',
			statement_no: '',
		},

		total: '',
		type: 'c',
		amount: '',
		is_deposit: false,
		is_less: false,
		statement_change: '',
		checked: 'c',
		currentStatement: {},
		amount: '',
		polo: '',
		cash_history_invoice: ''
	},

	computed: {
		
	},

	ready: function() {
		this.fetchToCash();
	},

	watch: {
		type : function() {
			if (this.type == 'd') {
				// console.log('aa');
				this.newInvoice.type = 'd';
				// this.checked = 'd';
				this.is_deposit = true;
				this.newInvoice.cash_amount = '';
			}
			else
			{
				this.type = 'c';
				this.newInvoice.type = 'c';
				this.is_deposit = false;
				this.checked = 'c';
				this.newInvoice.cash_amount = '';
			} 
		},
	},

	methods: {
		fetchToCash: function() {
			this.$http.get('/cms/api/fetch-to-cash/'+current_user_id+'?ajax=true', function(statements) {
				// console.log(statements[0].statement_invoice);
				this.$set('statements',statements);
			});
		},

		showInvoice: function(id,statement) {
			this.amount = parseInt(statement.remaining_amount) + parseInt(statement.amount) ;
			this.newInvoice.statement_no = statement.id;
			this.newInvoice.id = '';
			this.total = '';
			this.newInvoice.deposit = '';
			$('#cash_complete').modal('show');
			// console.log(id);
			this.$http.get('/cms/invoices/' + id, function(data) {
				// console.log(data[0].deposit);
				if (this.amount < data[0].total - data[0].deposit) {
					this.is_deposit = true;
					this.is_less = true;
					this.checked = 'd';
					this.type = 'd';
					this.newInvoice.type = 'd';
					this.newInvoice.cash_amount = '';
				}
				else 
				{
					this.is_deposit = false;
					this.is_less = false;
					this.checked = 'c';
					this.type = 'c';
					this.newInvoice.type = 'c';
					this.newInvoice.cash_amount = '';
				}
				this.newInvoice.id = data[0].id;
				this.newInvoice.invoice_no = data[0].invoice_no;
				this.total = data[0].total;
				this.newInvoice.deposit = data[0].deposit;
			});

			
		},

		showCashHistory: function(id) {
			$('#cash_history').modal('show');
			// console.log(id);
			this.$http.get('/cms/invoices/' + id, function(data) {
				this.cash_history_invoice = data;
				console.log(this.cash_history_invoice);
			});			
		},

		cashComplete: function(event) {
			// console.log(this.newInvoice.cash_amount);
			event.preventDefault();
			if (this.is_deposit == true && this.newInvoice.cash_amount >= (this.total - this.newInvoice.deposit)) {
				$('#cash_amount').focus();
				return alert('Deposit သည္ ေပးရမည့္ေငြထက္မ်ားလုိ႕မရပါ ။ လစာထဲက စုိက္ေပးခ်င္လုိ႕လား :P');
			}
			else {

				if (this.is_deposit == true && this.newInvoice.cash_amount > this.amount) {
					$('#cash_amount').focus();
					return alert('Deposit သည္ ေခ်ရမည့္ေငြလက္က်န္ထက္ မ်ားလုိ႕မရပါ။ စုိက္ခ်င္လုိ႕လား :P')
				}
				else{
					var invoice = this.newInvoice;
					this.newInvoice = {
						id: '',
						invoice_no: '',
						cash_amount: '',
						accountant_remark: '',
						type: 'c',
						statement_no: ''
					},
					this.cancelCashComplete();
					$.LoadingOverlay("show", {
						image       : "",
		    			fontawesome : "fa fa-spinner fa-spin"
					});
					// console.log(invoice);
					this.$http.patch('/cms/invoices/' + invoice.id, invoice).success(function(response) {
					this.fetchToCash();
					// this.edit = false;
					toastr.success('Successfully updated!');
					$('#cash_complete').modal('hide');
					$.LoadingOverlay("hide");   
					}).error(function(error) {
						toastr.success(error);
					});
				}
			}
		},

		deleteAssign: function(id) {
			if(confirm("Are you sure you want to delete this Assignments?")) {
				this.$http.patch('/cms/api/delete-assignments/'+ id).success(function(response) {
					this.fetchToCash();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},

		editPoLo: function(statement) {
			this.currentStatement = statement;
			$('#edit_amount').modal();
		},

		submitPoLo: function() {
			if (this.polo == 'l') {
				var amount = this.amount;
			}
			else var amount = -this.amount;

			

			if(confirm("Are You Sure?")) {
				$('#edit_amount').modal('hide');
				var data = {
					statement_amount: this.currentStatement.statement_amount,
					vendor_id : this.currentStatement.vendor_id,
					date: this.currentStatement.date,
					remaining_amount: this.currentStatement.remaining_amount,
					statement_no : this.currentStatement.statement_no,
					status : this.currentStatement.status,
					user_id: this.currentStatement.user_id,
					amount : amount,
				}

				this.$http.patch('/cms/api/update-polo/' + this.currentStatement.id, data).success(function(response) {
					this.amount = '';
					this.polo = '';
					this.currentStatement = {};
					$('#edit_amount').modal('hide');
					this.fetchToCash();
					toastr.success('Success!');
				}).error(function(error) {
					toastr.error(error);
				});
			}
		},

		cancelCashComplete: function() {
			// this.fetchInvoices();
			this.newInvoice.invoice_no = '';
			this.is_deposit = false;
			this.newInvoice.statement_no = '';
			this.total = '';
			this.deposit = '';
			this.amount = '';
			this.newInvoice.cash_amount = '';
			this.newInvoice.accountant_remark = '';
			this.checked = 'c';
			this.is_less = false;
			this.newInvoice.type = 'c';
		}	
	}
});