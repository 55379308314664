new Vue({
	el: '#director_report',

	data: {
		month: '',
		plan: ''
	},

	computed: {
		errors: function() {
			if (!this.month) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchReport();
		this.fetchPlans();
	},

	methods: {
		fetchReport: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/director-reports', function(reports) {
				this.$set('report', reports);
				$.LoadingOverlay("hide");
			});
		},

		getReport: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/director-reports?month='+this.month+'&plan='+this.plan, function(reports) {
				this.$set('report', reports);
				$.LoadingOverlay("hide");
			});	
		},

		fetchPlans: function() {
			this.$http.get('/cms/plans', function(plans) {
				this.$set('plans', plans);
			});
		}
	}
});