new Vue({
	el: '#vendor_report',

	data: {
		month: '',
		city: ''
	},

	watch: {
		state_change : function(id) {
			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.$set('cities',cities);
			});
		}
	},

	computed: {
		errors: function() {
			if (!this.month) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchStates();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states', function(states) {
				this.$set('states', states);
			});
		},

		getReport: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});

			this.$http.get('/cms/api/vendor-reports?month='+this.month+'&city='+this.city, function(reports) {
				this.$set('reports', reports);
				$.LoadingOverlay("hide");
			});	
		},

		exportReport: function() {
			var url = '/cms/api/vendor-reports?month='+this.month+'&city='+this.city+'&export=1';
			window.open(url, "_blank");
		},

		numberWithCommas: function(x) {
		    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
	}
});