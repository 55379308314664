new Vue({
	el: '#monthly-checking',

	data: {
		newSearch: {
			staff_id: '',
	        plan_id: '',
	        month: '',
		},

        pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },
	},

	computed: {
		errors: function() {
			if (! this.newSearch['staff_id'] || ! this.newSearch['plan_id'] || ! this.newSearch['month']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchStaffs();
		this.fetchPlans();
	},

	methods: {
		numberWithCommas: function(x) {
		    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},

		//Fetch staffs to filter invoices list
		fetchStaffs: function() {
			this.$http.get('/cms/office/staffs?ajax=true', function(staffs) {
				this.$set('staffs', staffs);
			});
		},

		fetchPlans: function() {
			this.$http.get('/cms/api/parent-with-child-products', function(plans) {
				this.$set('plans', plans);
			});
		},

		search: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/invoices/monthly/check?staff_id='+this.newSearch.staff_id+'&plan_id='+this.newSearch.plan_id+'&month='+this.newSearch.month, function(result) {
				this.$set('result', result);
				$.LoadingOverlay("hide");
			});
		}
	}
});
