new Vue({
	el: '#invoice',

	data: {
		newInvoice: {
			id: '',
			vendor_id : '',
			invoice_no: '',
			quantities : [],
			remarks : [],
			remark: '',
			deposit: '',
			cash_amount: '',
			accountant_remark: '',
			type: 'c',
			statement_no: '',
			deliver_date: '',
			date: ''
		},

		filter: {
			state_id: '',
			city_id: '',
			vendor: '',
			plan_id: '',
			from: '',
			to: ''
		},

		plan_discounts: [],
		date: '',

		consign_discount:[],
		consign: false,
		consign_credit: false,
		overdue: '',
		invoice_limit: 0,
		overdue_invoice: 0,
		target_bonus: '',
		consign_bonus: 0,

		total: '',
		type: 'c',
		amount: '',
		is_deposit: false,
		is_less: false,
		statement_change: '',
		checked: 'c',

		target_total : 0,

		pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },

		offset: 4,
		invoices: [],
		cities: [],
		vendors: [],
		plans: [],
		products: [],
		selected_products: [],
		selected_products_history: {},
		selected_plans_map: {},
		selected_credits_map: {},
		selected_plans: [],
		selected_credits: [],

		sortKey: '',

		reverse: 1,

		edit: false,

		showTargetDiscount: false,

		//For filters
        year: 0,
        month: 0,
        day: 0,
        staff: 0,
        reload: false,
        plan_id: 0,
        invoice_no: '',
        vendor: 0,
        cash_history_invoice: '',
        discount_invoice: {},
        child_invoices: {},
        staff_sale: 0,
        dis_amount: 0
	},

	watch: {
		state_change : function(id) {
			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.filter.state_id = id;
				this.$set('cities',cities);
			});
		},

		city_change : function(id) {
			this.$http.get('/cms/api/vendors-in-city/' + id,function(vendors) {
				this.filter.city_id = id;
				this.$set('vendors',vendors);
			});
		},

		reload : function() {
			this.fetchInvoices(this.pagination.current_page);
			this.$set("x",0);
			this.$delete("x");
		},


		dis_amount : function(data) {
			this.dis_amount = data;
		},

		statement_change : function(id) {
			if (id != 0) {
				this.$http.get('/cms/banks/' + id,function(bank) {
				// console.log(bank);
					this.newInvoice.statement_no = bank.id;
					this.amount = bank.remaining_amount;
					if (this.amount < this.total - this.newInvoice.deposit) {
						this.is_deposit = true;
						this.is_less = true;
						this.checked = 'd';
						this.type = 'd';
						this.newInvoice.type = 'd';
						this.newInvoice.cash_amount = '';
					}
					else 
					{
						this.is_deposit = false;
						this.is_less = false;
						this.checked = 'c';
						this.type = 'c';
						this.newInvoice.type = 'c';
						this.newInvoice.cash_amount = '';
					}
				})
			}
			
		},

		date: function() {
			this.newInvoice.date = this.date;
			this.$set("x",0);
			this.$delete("x");
		},

		type : function() {
			if (this.type == 'd') {
				// console.log('aa');
				this.newInvoice.type = 'd';
				// this.checked = 'd';
				this.is_deposit = true;
				this.newInvoice.cash_amount = '';
			}
			else
			{
				this.type = 'c';
				this.newInvoice.type = 'c';
				this.is_deposit = false;
				this.checked = 'c';
				this.newInvoice.cash_amount = '';
			} 
		},

		deliver_date: function(date) {
			this.$http.get('/cms/api/staff-sale/' + date,function(sale) {
				this.staff_sale = sale;	
				console.log(this.staff_sale.length);
			});
		},

		vendorChange: function(id) {
			this.newInvoice.vendor_id = id;
			this.selected_plans_map = {};
			this.selected_credits_map = {};
			this.selected_plans = [];
			this.selected_credits = [];
			this.target_bonus = 0;
			if (id != 0) {
				this.$http.get('/cms/vendors/' + id,function(vendor) {
					if (parseInt(vendor.invoice_limit) >= parseInt(vendor.overdue_invoice) - 1) {
						this.overdue = 'o';
					}
					else{
						this.overdue = 'n';
					}
					// console.log(this.overdue);
					this.invoice_limit = vendor.invoice_limit;
					this.overdue_invoice = vendor.overdue_invoice;
				});

				this.$http.get('/cms/target/' + id+'/'+this.newInvoice.date,function(targets) {
					if (targets.length > 0) {
						var total = 0, target;
						for (var i = 0; i < targets.length; i++) {
							target = targets[i];
							total += parseInt(target.remaining_discount);
						};
						this.$set('targets',targets);
						this.target_total = total;
						this.showTargetDiscount = true;
					}
					else{
						this.showTargetDiscount = false;
					}
				});

			}
		},

		product_change : function(id) {
			this.selected_products_history[id] = 0;
			this.$http.get('/cms/getMonthSalesByProduct/' + id+'/'+this.newInvoice.vendor_id+'/'+this.newInvoice.date,function(sales) {
				this.selected_products_history[id] = sales ? sales : 0;
				this.$set("x",0);
				this.$delete("x");
			});
			//check target and agreed amount
			this.$http.get('/cms/api/checkAgreedamount/'+ id+'/'+this.newInvoice.vendor_id+'/'+this.newInvoice.date,function(invoice_percent){
				
				//get plan
				if (invoice_percent == 0) {
					this.$http.get('/cms/products/' + id, function(data) {
						if (!(data.plan.id in this.selected_plans_map)) {
							this.selected_plans_map[data.plan.id] = {
								count: 1,
								index: this.selected_plans.length,
								detail: data.plan
							};
							this.selected_plans.push(data.plan);
						} else {
							this.selected_plans_map[data.plan.id].count+= 1;
						}
					});
				}


				else{
					this.$http.get('/cms/products/' + id, function(data) {
						if (!(data.plan.id in this.selected_plans_map)) {
							this.selected_plans_map[data.plan.id] = data.plan;
							var plan = data.plan;
							plan.percent = invoice_percent;
							this.selected_plans.push(plan);
						}
					});
				}

			});

			//check target and agreed amount
			this.$http.get('/cms/api/checkCredit/'+ id+'/'+this.newInvoice.vendor_id,function(credit){
				//get plan
				// if (credit == 0) {
				// 	this.$http.get('/cms/products/' + id, function(data) {
				// 		if (!(data.plan.id in this.selected_plans_map)) {
				// 			this.selected_credits_map[data.plan.id] = {
				// 				count: 1,
				// 				index: this.selected_credits.length,
				// 				detail: data.plan
				// 			};
				// 			this.selected_credits.push(data.plan);
				// 		} else {
				// 			this.selected_credits_map[data.plan.id].count+= 1;
				// 		}
				// 	});
				// }


				// else{
					this.$http.get('/cms/products/' + id, function(data) {
						if (!(data.plan.id in this.selected_credits_map)) {
							this.selected_credits_map[data.plan.id] = data.plan;
							var plan = data.plan;
							plan.credit = credit;
							this.selected_credits.push(plan);
						}
					});

				// }


			});

			
			for(var i = 0; i < this.products.length; i++) {
				var product = this.products[i];
				if (product.id == id) {
					this.selected_products.push(product);
					this.products.$remove(product);
					break;
				}
			}

			this.$set('product_s', this.selected_products);
			this.$set('products',this.products);

		},

		//Yearly filter
		year_filter: function(year) {
			if(year == 0) this.year = new Date().getFullYear();
			else this.year = year;
			
			//request url to get yearly invoices
			var request = 'year='+ this.year;
			//if month is exist, change request url to get dedicated month invoices
			if(this.month > 0) request = request + '&month=' + this.month;
			//if day is exist, change request url to dedicated day invoices
			if(this.day > 0) request = request + '&day=' + this.day;
			//if this.staff (id) is greater than default value
			if(this.staff > 0) request = request + '&staff=' + this.staff;
			//if vendor is exist
			if(this.vendor > 0) request = request + '&vendor=' + this.vendor;
			//if plan
			if(this.plan_id > 0) request = request + '&plan_id=' + this.plan_id;
			//pagination page
			request = request + '&page=' + 1;
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/invoices?'+request,function(response) {
				this.$set('invoices', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});	
		},

		//Monthly filter
		ym_filter: function(month) {
			this.month = month;
			//request url to get monthly invoices
			var request = 'year='+ this.year + '&month=' + month;
			//if month is 0, change request url to get yearly invoices
			if(month == 0) request = 'year='+ this.year;
			//if day is exist, change request url to get daily invoices
			if(this.day > 0) request = request + '&day=' + this.day;
			//if this.staff (id) is greater than default value
			if(this.staff > 0) request = request + '&staff=' + this.staff;
			//if vendor is exist
			if(this.vendor > 0) request = request + '&vendor=' + this.vendor;
			//If plan
			if(this.plan_id > 0) request = request + '&plan_id=' + this.plan_id;
			//pagination page
			request = request + '&page=' + 1;
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/invoices?'+request,function(response) {
				this.$set('invoices', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});	
		},

		//Daily Filter
		ymd_filter: function(day) {
			this.day = day;
			//request url to get daily invoices
			var request = 'year='+ this.year + '&month=' + this.month + '&day=' + day;
			//if day is 0, change request url to get monthly invoices
			if (day == 0) request = 'year='+ this.year + '&month=' + this.month;
			//if this.staff (id) is greater than default value
			if(this.staff > 0) request = request + '&staff=' + this.staff;
			//If plan 
			if(this.plan_id > 0) request = request + '&plan_id=' + this.plan_id;
			//if vendor is exist
			if(this.vendor > 0) request = request + '&vendor=' + this.vendor;
			//pagination page
			request = request + '&page=' + 1;
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/invoices?'+request,function(response) {
				this.$set('invoices', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});	
		},

		//Filter with dedicated staff
		staff_filter: function(staff) {
			this.staff = staff;
			var request = 'page=' + 1;
			
			if(staff != 0) request = request + '&staff='+staff;
			//if year is exist, change request url 
			if(this.year > 0) request = request + '&year=' + this.year;
			//if month is exist, change request url to get dedicated month invoices
			if(this.month > 0) request = request + '&month=' + this.month;
			//if day is exist, change request url to dedicated day invoices
			if(this.day > 0) request = request + '&day=' + this.day;
			//if vendor is exist
			if(this.vendor > 0) request = request + '&vendor=' + this.vendor;
			//If plan
			if(this.plan_id > 0) request = request + '&plan_id=' + this.plan_id;
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/invoices?'+request,function(response) {
				this.$set('invoices', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});

			this.$http.get('/cms/api/fetch-vendors-by-staff/'+staff+'?ajax=true', function(vendors) {
				this.$set('vendors',vendors);
			});
		},

		//Filter with dedicated vendor
		vendor_filter: function(vendor) {
			this.vendor = vendor;
			var request = 'page=' + 1;
			
			if(vendor != 0) request = request + '&vendor='+vendor;
			//if year is exist, change request url 
			if(this.year > 0) request = request + '&year=' + this.year;
			//if month is exist, change request url to get dedicated month invoices
			if(this.month > 0) request = request + '&month=' + this.month;
			//if day is exist, change request url to dedicated day invoices
			if(this.day > 0) request = request + '&day=' + this.day;
			//if this.staff (id) is greater than default value
			if(this.staff > 0) request = request + '&staff=' + this.staff;
			//If plan
			if(this.plan_id > 0) request = request + '&plan_id=' + this.plan_id;
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/invoices?'+request,function(response) {
				this.$set('invoices', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});
		},

		// Filter with plan
		plan_filter: function(plan) {
			this.plan_id = plan;
			var request = 'page=' + 1;
			
			if(plan != 0) request = request + '&plan_id='+plan;
			//If staff 
			if(this.staff > 0) request = request + '&staff='+this.staff;
			//if vendor is exist
			if(this.vendor > 0) request = request + '&vendor=' + this.vendor;
			//if year is exist, change request url 
			if(this.year > 0) request = request + '&year=' + this.year;
			//if month is exist, change request url to get dedicated month invoices
			if(this.month > 0) request = request + '&month=' + this.month;
			//if day is exist, change request url to dedicated day invoices
			if(this.day > 0) request = request + '&day=' + this.day;	
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/invoices?'+request,function(response) {
				this.$set('invoices', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});
		},

		get_vendors: function(staff_id) {
			if(staff_id != 0) {
				this.$http.get('/cms/api/fetch-vendors-by-staff/'+staff_id+'?ajax=true', function(vendors) {
					this.$set('vendors',vendors);
				});
			} else {
				this.$http.get('/cms/api/fetch-vendors-by-staff?ajax=true', function(vendors) {
					this.$set('vendors',vendors);
				});
			}
		},
	},

	computed: {
		errors: function() {
			if (this.target_total < this.target_bonus || this.newInvoice.vendor_id == 0 || this.selected_products.length == 0){
				// this.target_bonus = 0;
				// alert('Your Bonus Amount is greater than Total Bonus.');
				// $('#target_bonus').focus();
				return true;	
			}else{
				return false;
			}
		},

		noVendor: function() {
			if(! this.filter.vendor) return true;
			return false;
		},

		//Disable month select input if year is not selected (Monthly Filter)
		noYearSelected: function() {
			if(this.year == 0) return true;
			return false;
		},
		//Disable day select input if month is not selected
		noMonthSelected: function() {
			if(this.month == 0) return true;
			return false;
		},

		cash_error: function() {
			if (! this.newInvoice.statement_no) return true;
			return false;
		},
		
		isActived: function () {
            return this.pagination.current_page;
        },
        
        pagesNumber: function () {
            if (!this.pagination.to) {
                return [];
            }
            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }

            return pagesArray;
        }
	},

	ready: function() {
		this.fetchInvoices(this.pagination.current_page);
		this.fetchStaffs();
		this.fetchPlans();
		this.fetchVendors();
		this.fetchProducts();
		this.fetchBankStatements();
		this.fetchStates();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states', function(states) {
				this.$set('states', states);
			});
		},

		numberWithCommas: function(x) {
		    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},

		fetchProducts: function() {
			this.$http.get('/cms/api/fetchproducts?ajax=true', function(products) {
				this.products = products;
			});
		},

		approveInvoice: function(id) {
			var that = this;
			swal({
                title: "<span style='color:#999'>သေချာပါသလား<span>",
                text: "<span style='font-size:14px;'>Invoice ကို သေချာစွာစစ်ဆေးပါသလား ။ ယခု Invoice အတွက် Account Depertmant မှ သင်တာဝန်ယူရမှာဖြစ်ပါတယ်။ သေချာစွာစစ်ဆေးပါ။</span>",
                html: true,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'သေချာတယ်',
                cancelButtonText: "ပြန်စစ်မယ်",
                closeOnConfirm: false,
                closeOnCancel: true,
                showLoaderOnConfirm: true
            }, 

            function(isConfirm) {
            	if(isConfirm) {
					that.$http.patch('/cms/api/invoices/approve/' + id).success(function(response) {
						swal({
		                    title: 'Saved!',
		                    text: "<span style='font-size:14px;'>Invoice အား သင့်နာမည်ဖြင့် စစ်ဆေးပြီးကြောင်း စာရင်းသွင်းပြီးဖြစ်ပါသည်။</span>",
		                    html:true,
		                    type: 'success',
		                    confirmButtonText: 'ကျေးဇူးပါ',
			                closeOnConfirm: false,
			                closeOnCancel: false
		                  },
		                  function(isConfirm){
		                  	window.location.href = "http://172.31.135.101/cms/invoices";
		                  });
						
					}).error(function(error) {
						swal(
		                    'Error !',
		                    error.message,
		                    'error'
		                  );
					});
            	}
            });
			// window.close();
		},

		dateCompare: function(last_date)
		{
			var l_date = moment(last_date).format('YYYY-MM-DD HH:mm:ss');
			var c_date = moment(this.newInvoice.date).format('YYYY-MM-DD HH:mm:ss');
			return l_date > c_date;
		},

		//Fetch staffs to filter invoices list
		fetchStaffs: function() {
			this.$http.get('/cms/office/staffs?ajax=true', function(staffs) {
				this.$set('staffs', staffs);
			});
		},

		showConsignment: function() {
			// this.selected_products = [];
			// this.$set('product_s', this.selected_products);
			// this.fetchProducts();
			this.consign = !this.consign;
			if (this.consign == false && this.consign_credit == true) {
				this.consign_credit = false;
			}
			// if (this.consign == false) {
			// 	this.consign_discount = [];
			// 	console.log(this.selected_plans);
			// }
			// else {
			// 	this.selected_plans_map = {};
			// 	this.selected_plans = [];
			// 	this.plan_discounts = [];
			// }
		},

		sortBy: function(sortKey) {
			if (this.sortKey == sortKey) {
				this.reverse = this.reverse * (-1);
			}
			this.sortKey = sortKey;
		},

		fetchVendors: function() {
			this.$http.get('/cms/api/fetch-vendors-by-staff/'+current_user_id+'?ajax=true', function(vendors) {
				this.$set('vendors',vendors);
			});
		},

		fetchPlans: function() {
			this.$http.get('/cms/api/parent-with-child-products', function(plans) {
				this.$set('plans', plans);
			});
		},

		fetchInvoices: function(page) {
			//request url to get monthly invoices
			var request = '?page=' + page;
			
			if(this.staff > 0) request = request + '&staff='+this.staff;
			//if year is exist, change request url 
			if(this.year > 0) request = request + '&year=' + this.year;
			//if month is exist, change request url to get dedicated month invoices
			if(this.month > 0) request = request + '&month=' + this.month;
			//if day is exist, change request url to dedicated day invoices
			if(this.day > 0) request = request + '&day=' + this.day;
			//if vendor is exist
			if(this.vendor > 0) request = request + '&vendor=' + this.vendor;
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
    		this.$http.get('/cms/api/invoices'+ request,function(response) {
				this.$set('invoices', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});
		},

		showInvoice: function(id) {
			this.newInvoice.id = '';
			this.total = '';
			this.newInvoice.deposit = '';
			$('#cash_complete').modal('show');
			// console.log(id);
			this.$http.get('/cms/invoices/' + id, function(data) {
				this.newInvoice.id = data.id;
				this.newInvoice.invoice_no = data.invoice_no;
				this.total = data.total;
				this.newInvoice.deposit = data.deposit;
			});
		},

		fetchBankStatements: function(){
			this.$http.get('/cms/banks?ajax=true', function(banks){
				this.$set('statements',banks);
			});
		},

		cashComplete: function(event) {
			// console.log(this.newInvoice.cash_amount);
			event.preventDefault();
			if (this.is_deposit == true && this.newInvoice.cash_amount >= (this.total - this.newInvoice.deposit)) {
				$('#cash_amount').focus();
				return alert('Your Deposit Amount is greater than Total.');
			}
			else {
				var invoice = this.newInvoice;
				this.newInvoice = {
					id: '',
					invoice_no: '',
					cash_amount: '',
					accountant_remark: '',
					type: 'c',
					statement_no: ''
				},
				this.cancelCashComplete();
				// console.log(invoice);
				this.$http.patch('/cms/invoices/' + invoice.id, invoice).success(function(response) {
				this.fetchInvoices(this.pagination.current_page);
				// this.edit = false;
				toastr.success('Successfully updated!');
				$('#cash_complete').modal('hide');
				}).error(function(error) {
					toastr.success(error);
				});
			}
		},

		deleteInvoice(id)
		{
			if(confirm("Are you sure you want to delete this Invoice?")) {
				this.$http.delete('/cms/invoices/'+ id).success(function(response) {
					this.fetchInvoices(this.pagination.current_page);
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error);
				});
			}
		},

		childInvoice: function(id)
		{
			$('#child_invoices').modal('show');
			this.$http.get('/cms/api/child-invoices/' + id, function(data) {
				this.child_invoices = data;
				console.log(this.child_invoices);
			});	
		},

		discountInvoice: function(id)
		{
			$('#child_invoices').modal('hide');
			$('#discount_invoice').modal('show');
			this.$http.get('/cms/invoices/' + id, function(data) {
				this.discount_invoice = data;
				if (this.discount_invoice[0].discount_amount != 0) {
					console.log(this.discount_invoice[0].discount_amount);
					this.dis_amount = (this.discount_invoice[0].discount_amount/this.discount_invoice[0].subtotal)*100;
					this.dis_amount = Math.round(this.dis_amount * 100)/100;
				}
			});	
		},

		applyDiscountInvoice: function()
		{
			if(confirm("Are You Sure?")) {
				$('#discount_invoice').modal('hide');
				var data = {
						discount_amount: this.dis_amount,
					}
				this.$http.patch('/cms/api/update-invoice-discount/' + this.discount_invoice[0].id, data).success(function(response) {
						this.discount_invoice = {};
						this.dis_amount = 0;
						this.fetchInvoices(this.pagination.current_page);
						toastr.success('Success!');
					}).error(function(error) {
						toastr.error(error);
				});
			}
		},

		showCashHistory: function(id) {
			console.log('fuck');
			$('#cash_history').modal('show');
			// console.log(id);
			this.$http.get('/cms/invoices/' + id, function(data) {
				this.cash_history_invoice = data;
			});			
		},

		changePage: function (page) {
            this.pagination.current_page = page;
            this.fetchInvoices(page);
        },

		removeProduct: function(id) {
			for(var i = 0; i < this.selected_products.length; i++) {
				var product = this.selected_products[i];
				if (product.id == id) {
					this.selected_plans_map[product.plan_id].count-= 1;

					if (this.selected_plans_map[product.plan_id].count == 0) {
						var pIndex = this.selected_plans_map[product.plan_id].index;
						delete this.selected_plans_map[product.plan_id];
						this.selected_plans.splice(pIndex, 1);
						this.selected_credits.splice(pIndex, 1);
					}

					this.products.push(product);
					this.selected_products.$remove(product);
					break;
				}

			}
		},

		cancelCashComplete: function() {
			// this.fetchInvoices();
			this.statement_change = 0;
			this.newInvoice.invoice_no = '';
			this.is_deposit = false;
			this.newInvoice.statement_no = '';
			this.total = '';
			this.deposit = '';
			this.amount = '';
			this.newInvoice.cash_amount = '';
			this.newInvoice.accountant_remark = '';
			this.checked = 'c';
			this.is_less = false;
			this.newInvoice.type = 'c';
		},

		getByInvoiceNo: function() {
			if (this.invoice_no) {
				this.$http.get('/cms/api/invoices/'+this.invoice_no,function(response) {
					this.$set('invoices', response);
					this.pagination = {
					            total: 0,
					            per_page: 7,
					            from: 1,
					            to: 0,
					            current_page: 1
					        }
				});
			} else {
				this.fetchInvoices(this.pagination.current_page);
			}
		},

		showFilterModal: function() {
			$('#filter').modal('show');
		},

		filterInvoices: function() {
			$('#filter').modal('hide');
			var request = '?action=filter&vendor='+this.filter.vendor+'&plan_id='+this.filter.plan_id+'&from='+this.filter.from+'&to='+this.filter.to+'&state_id='+ this.filter.state_id + '&city_id=' + this.filter.city_id;
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
    		this.$http.get('/cms/api/invoices'+ request,function(response) {
				this.$set('invoices', response.data);
				this.$set('pagination', response.pagination);
				this.$set('payment', response.payment);
				$.LoadingOverlay("hide");
			});
		}
	}
});
