new Vue({
	el: '#plan',

	data: {
		newPlan: {
			id: '',
			name: '',
			parent: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newPlan['name']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchPlans();
		this.fetchParentPlans();
	},

	methods: {
		fetchPlans: function() {
			this.$http.get('/cms/plans?ajax=true', function(plans) {
				this.$set('plans', plans);
			});
		},

		fetchParentPlans: function() {
			this.$http.get('/cms/api/parent-plans', function(response) {
				this.$set('parent_plans', response);
			});
		},

		createPlan: function(){
			var plan = this.newPlan;
			this.newPlan = {
				name: '',
				parent: ''
			},
			this.$http.post('/cms/plans', plan, function(data) {
				toastr.success('Successfully created new plan.');
				this.fetchPlans();
			});
		}, 

		showPlan: function(id) {
			this.edit = true
			this.$http.get('/cms/plans/' + id, function(data) {
				this.newPlan.id = data.id
				this.newPlan.name = data.name
			});
		},

		editPlan: function(id) {
			var plan = this.newPlan;
			this.newPlan = {
				id: '',
				name: '',
				parent: ''
			};

			this.$http.patch('/cms/plans/' + id, plan).success(function(response) {
				this.fetchPlans();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelPlanEdit: function() {
			this.newPlan = {
				id: '',
				name: ''
			};
			this.edit = false;
		},

		deletePlan: function(id) {
			if(confirm("Are you sure you want to delete this Plan?")) {
				this.$http.delete('/cms/plans/'+ id).success(function(response) {
					this.fetchPlans();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});