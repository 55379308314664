new Vue({
	el: '#calendar',

	data: {
		newCalendar: {
			id: '',
			year: '',
			month: '',
			day_count: '',
			group: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newCalendar['year'] || !this.newCalendar['month'] || !this.newCalendar['day_count'] ) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchCalendars();
	},

	methods: {
		fetchCalendars: function() {
			this.$http.get('/cms/calendars?ajax=true', function(calendars) {
				this.$set('calendars', calendars);
			});
		},

		createCalendar: function(){
			var calendar = this.newCalendar;
			this.newCalendar = {
				year: '',
				month: '',
				day_count: '',
				group: ''
			},
			this.$http.post('/cms/calendars', calendar, function(data) {
				toastr.success('Successfully created new calendar.');
				this.fetchCalendars();
			});
		}, 

		showCalendar: function(id) {
			this.edit = true
			this.$http.get('/cms/calendars/' + id, function(data) {
				this.newCalendar.id = data.id;
				this.newCalendar.year = data.year;
				this.newCalendar.month = data.month;
				this.newCalendar.day_count = data.day_count;
				this.newCalendar.group = data.group;
			});
		},

		editCalendar: function(id) {
			var calendar = this.newCalendar;
			this.newCalendar = {
				id: '',
				year: '',
				month: '',
				day_count: '',
				group: ''
			};

			this.$http.patch('/cms/calendars/' + id, calendar).success(function(response) {
				this.fetchCalendars();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelCalendarEdit: function() {
			this.newCalendar = {
				id: '',
				year: '',
				month: '',
				day_count: '',
				group: ''
			};
			this.edit = false;
		},

		deleteCalendar: function(id) {
			if(confirm("Are you sure you want to delete this Calendar?")) {
				this.$http.delete('/cms/calendars/'+ id).success(function(response) {
					this.fetchCalendars();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});