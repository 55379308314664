new Vue({
	el: '#office-staffs',

	data: {
		newSupervisor: {
			id: '',
			name: '',
			username: '',
			password: '',
			role: 'supervisor',
			state_ids: []
		},

		newStaff: {
			id: '',
			name: '',
			username: '',
			password: '',
			role: '',
			parent_id: 0
		},

		newTarget: {
			user_id: '',
			plan_id: '',
			year: '',
			season: '',
			month: '',
			target_amount: 0,
		},

		state_id: '',
		// city_id: '',

		edit: false
	},

	computed: {
		supervisor_inputs: function() {
			if (! this.newSupervisor['name'] || !this.newSupervisor['username'] || !this.newSupervisor['password']) return true;
			return false;
		},

		staff_inputs: function() {
			if (! this.newStaff['name'] || !this.newStaff['username'] || !this.newStaff['password']) return true;
			return false;
		},

		can_submit_new_target: function() {
			if(! this.newTarget['plan_id'] || ! this.newTarget['year'] || ! this.newTarget['season'] || ! this.newTarget['month']) return true;
			return false;
		},
	},

	watch: {
		state_change : function(id) {
			// this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
			// 	this.$set('cities',cities);
			// });
			this.$http.get('/cms/api/supervisors-in-state?state_id=' + id, function(supervisors) {
				this.$set('supervisors_in_s', supervisors);
			});
		}
	},

	ready: function() {
		this.fetchSupervisors();
		this.fetchStaffs();
		this.fetchStates();
		this.fetchPlans();
	},

	methods: {
		fetchSupervisors: function() {
			this.$http.get('/cms/office/supervisors?ajax=true', function(supervisors) {
				this.$set('supervisors', supervisors);
			});
		},

		fetchStaffs: function() {
			this.$http.get('/cms/office/staffs?ajax=true', function(staffs) {
				this.$set('staffs', staffs);
			});
		},

		fetchStates: function() {
			this.$http.get('/cms/states?ajax=true', function(states) {
				this.$set('states', states);
			});
		},

		fetchPlans: function() {
			this.$http.get('/cms/plans', function(plans) {
                this.$set('plans', plans);
			});	
		},

		addState: function() {
			var state = this.state_id;
			if(state != 0)
			{
				this.newSupervisor.state_ids.push(state);
				var $selected = $('#states option:selected');
				var label = $selected.html();

				$selected.remove();
				var html = '<p class="input-group"><span class="input-group-addon" style="cursor:pointer;"><i class="fa fa-minus remove-role-input"></i></span><input readonly type="text" class="form-control" name="'+state+'" value="'+label+'"/></p>';

				$('#state_input_container').append(html);
				this.state_id = '';
			}
		},

		createSupervisor: function(){
			var supervisor = this.newSupervisor;
			this.newSupervisor = {
				name: '',
				username: '',
				password: '',
				state_ids: []
			},
			$('#state_input_container').html('');

			this.$http.post('/cms/office/office-user', supervisor, function(data) {
				toastr.success('Successfully created new user.');
				this.fetchSupervisors();
				this.fetchStates();
			});
		}, 

		createStaff: function(){
			var staff = this.newStaff;
			
			this.newStaff = {
				name: '',
				username: '',
				password: '',
				parent_id: 0,
				role: ''
				// cities_ids: []
			},
			// $('#city_input_container').html('');

			this.$http.post('/cms/office/office-user', staff, function(data) {
				toastr.success('Successfully created new user.');
				this.fetchStaffs();
				this.fetchStates();
			});
		},

		deleteOfficeUser: function(id) {
			if(confirm("Are you sure you want to delete this user?")) {
				this.$http.delete('/cms/office/office-user/'+ id).success(function(response) {
					this.fetchSupervisors();
					this.fetchStaffs();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},

		setStatus: function(id) {
			if(confirm("Are you sure?")) {
				this.$http.get('/cms/api/users/'+ id +'/set-status').success(function(response) {
					this.fetchSupervisors();
					this.fetchStaffs();
					toastr.success('Success!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},

		addTarget: function(staff) {
			$("#add-user-target .modal-title").html(staff.name);
			this.newTarget.user_id = staff.id;
			$('#add-user-target').modal('show');	
		},

		createTarget: function() {
			var data = this.newTarget;
			
			// /cms/api/vendors/targets/create
			if (confirm("Are you sure! Agreed amount cannot edit(update) after created.")) {
				this.$http.post('/cms/api/targets/create', data).success(function(response) {
					this.newTarget.plan_id = '';
					this.newTarget.target_amount = 0;

					toastr.success('Agreed amount သတ္မွတ္ပီးပါပီ။');
				}).error(function(error) {
					toastr.error(error.message);
				});
			}
		}
	}
});