new Vue({
	el: '#shop',

	directives: {
		typeahead: {
			bind: function() {
				var that = this;
				$(this.el).typeahead({
				    ajax: {
				    	url: '/cms/api/cities/search',
				    	displayField: 'title',
				    	preProcess: function(data) {
				    		return data;
				    	}
				    },
				    onSelect: function(item) {
				    	that.vm.fetchSearchShops(item.value);
				    	setTimeout(function() {
				    		$(that.el).val('');
				    	}, 200);
				    }
				});
			}
		}
	},

	data: {
		newVendor: {
			id: '',
			state_id: '',
			city_id: '',
			name: '',
			address: '',
			phone: '',
			plans:[6]
		},
		state_change: '0',
		city_change: '0',
		cities: [],
		states: [],
		allShops: [],
		shops: [],
		messages: [],
		msg1: [],
		msg2: [],

		city_id_s: '0',

		edit: false
	},

	watch: {
		city_id_s : function(id) {
			this.shops = [];
			for(var i = 0; i < this.allShops.length; i++) {
				var shop = this.allShops[i];
				if (shop.city_id == id) {
					this.shops.push(shop);
				}
			}
			this.$set('shops', this.shops);
			this.randomMessage();
		},
		state_change : function(id) {
			var selectedCities = [];
			for (var i = 0; i < this.cities.length; i++) {
				var city = this.cities[i];
				if (city.state_id == id) {
					selectedCities.push(city);
				}
			};
			this.$set('selectedCities', selectedCities);
			this.$nextTick(function() {
				this.city_change = this.newVendor.city_id;
			});
		},
		city_change : function(id) {
			this.newVendor.city_id = this.city_change;
		}
	},

	computed: {
		errors: function() {
			if (! this.newVendor['name']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchShops();
		this.fetchStates();
		this.fetchCities();
		this.fetchMessages();
		this.randomMessage();
	},

	methods: {
		fetchShops: function() {
			this.$http.get('/cms/api/swiss-formula/shops', function(shops) {
				this.$set('shops', shops);
				this.allShops = shops;
			});
		},

		fetchCities: function() {
			this.$http.get('/cms/cities', function(cities) {
				this.$set('cities', cities);
			});
		},

		fetchStates: function() {
			this.$http.get('/cms/states', function(states) {
				this.$set('states', states);
			});
		},

		fetchSearchShops: function(id) {
			this.shops = [];
			for(var i = 0; i < this.allShops.length; i++) {
				var shop = this.allShops[i];
				if (shop.city_id == id) {
					this.shops.push(shop);
				}
			}
			this.$set('shops', this.shops);
			this.randomMessage();
		},

		fetchMessages: function() {
			this.$http.get('/cms/messages', function(messages) {
				this.$set('messages',messages);
				this.messages = messages;
				this.msg1 = [];
				this.msg2 = [];
				var message;
				for(var i = 0;i < this.messages.length; i++){
					message = this.messages[i];
					if (message.type == 's1') {
						this.msg1.push(message.message);
					}
					else this.msg2.push(message.message);
				}
			});
		},

		createVendor: function(){
			var vendor = this.newVendor;
			this.newVendor = {
				city_id: '0',
				state_id: '0',
				name: '',
				address: '',
				phone: '',
				plans: [6]
			};
			console.log(vendor);
			this.$http.post('/cms/vendors', vendor, function(data) {
				toastr.success('Successfully created new Vendor.');
				this.fetchShops();
			});
		}, 

		showVendor: function(id) {
			this.edit = true;
			this.$http.get('/cms/vendors/' + id, function(data) {
				console.log(data);
				this.newVendor.id = data.id;
				this.newVendor.name = data.name;
				this.newVendor.state_id = data.city.state.id;
				this.newVendor.address = data.address;
				this.newVendor.phone = data.phone;
				this.newVendor.city_id = data.city.id;
				var plans = [];
				for (var i = 0; i < data.plans.length; i++) {
					plans.push(data.plans[i].id);
				};
				this.newVendor.plans = plans;

				if (this.state_change == data.city.state.id) {
					this.city_change = data.city.id;
				}

				this.state_change = data.city.state.id;
			});
		},

		editVendor: function(id) {
			var vendor = this.newVendor;
			this.newVendor = {
				id: '',
				state_id: '0',
				city_id: '0',
				name: '',
				address: '',
				phone: '',
				plans: []
			};

			this.$http.patch('/cms/vendors/' + id, vendor).success(function(response) {
				this.fetchShops();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelVendorEdit: function() {
			this.newVendor = {
				id: '',
				city_id: '0',
				state_id: '0',
				name: '',
				address: '',
				phone: '',
				plans: []
			};
			this.edit = false;
		},

		deleteVendor: function(id) {
			if(confirm("Are you sure you want to delete this Vendor?")) {
				this.$http.delete('/cms/vendors/'+ id).success(function(response) {
					this.fetchShops();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error);
				});
			}
		},

		randomMessage: function() {
			var s1 = this.msg1[Math.floor(Math.random() * this.msg1.length)];
			var s2 = this.msg2[Math.floor(Math.random() * this.msg2.length)];
			this.$set('s1', s1);
			this.$set('s2', s2);
		}
	}
});

new Vue({
	el: '#sf_sale',

	data: {
		newSale: {
			id: '',
			state_id: '',
			city_id: '',
			vendor_id: '',
			quantity: '',
			date: ''
		},

		state_change: '0',
		city_change: '0',
		vendor_change: '0',

		edit: false
	},

	watch: {
		state_change : function(id) {
			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.$set('cities',cities);
				this.$nextTick(function() {
					this.city_change = this.newSale.city_id;
				});
			});
		},
		city_change : function(id) {
			this.newSale.city_id = this.city_change;
			this.$http.get('/cms/api/swiss-formula/shops-by-city?city_id=' + id,function(vendors) {
				this.$set('vendors',vendors);
				this.$nextTick(function() {
					this.vendor_change = this.newSale.vendor_id;
				});
			});
		},
		vendor_change : function(id) {
			this.newSale.vendor_id = this.vendor_change;
		}
	},

	computed: {
		errors: function() {
			if (! this.newSale['quantity'] || ! this.newSale['vendor_id']) return true;
			return false;
		}
	},

	ready: function() {
		this.fecthSales();
		this.fetchStates();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states', function(states) {
				this.$set('states', states);
			});
		},

		fecthSales: function() {
			this.$http.get('/cms/api/swiss-formula/sales', function(sales) {
				this.$set('sales', sales);
			});
		},

		createSale: function(){
			var sale = this.newSale;
			this.newSale = {
				quantity: '',
				date: '',
				vendor_id: '',
				state_id: '',
				city_id: ''
			},

			this.$http.post('/cms/swiss-formula/sales', sale, function(data) {
				toastr.success('Successfully created new sale.');
				this.fecthSales();
			});
		}, 

		showSale: function(id) {
			this.edit = true
			this.$http.get('/cms/swiss-formula/sales/' + id, function(data) {
				this.newSale.id = data.id;
				this.newSale.quantity = data.quantity;
				this.newSale.date = data.date;
				this.newSale.vendor_id = data.vendor.id;
				this.newSale.state_id = data.vendor.city.state.id;
				this.newSale.city_id = data.vendor.city.id;

				if (this.state_change == data.vendor.city.state.id) {
					this.city_change = data.vendor.city.id;
				}

				if (this.city_change == data.vendor.city.id) {
					this.vendor_change = data.vendor.id;
				}

				this.state_change = data.vendor.city.state.id;
			});
		},

		editSale: function(id) {
			var sale = this.newSale;
			this.newSale = {
				id: '',
				quantity: '',
				date: '',
				vendor_id: ''
			};

			this.$http.patch('/cms/swiss-formula/sales/' + id, sale).success(function(response) {
				this.fecthSales();
				this.edit = false;
				this.$set('state_change', '0');
				this.$set('cities', []);
				this.$set('city_change', '0');
				this.$set('vendors', []);
				this.$set('vendor_change', '0');
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelSaleEdit: function() {
			this.newSale = {
				id: '',
				quantity: '',
				date: '',
				vendor_id: ''
			};
			this.edit = false;
		},

		deleteSale: function(id) {
			if(confirm("Are you sure you want to delete this Sale?")) {
				this.$http.delete('/cms/swiss-formula/sales/'+ id).success(function(response) {
					this.fecthSales();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});