new Vue({
	el: '#monthly_report',

	data: {
		month: ''
	},

	computed: {
		errors: function() {
			if (!this.month) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchReport();
	},

	methods: {
		fetchReport: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/monthly-reports', function(reports) {
				this.$set('reports', reports);
				$.LoadingOverlay("hide");
			});
		},

		getReport: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/monthly-reports?month='+this.month, function(reports) {
				this.$set('reports', reports);
				$.LoadingOverlay("hide");
			});	
		}
	}
});