new Vue({
	el: '#office-user-detail',

	directives: {
		citytypeahead: {
			bind: function() {
				var that = this;
				$(this.el).typeahead({
				    ajax: {
				    	url: '/cms/api/city-of-supervisor?supervisor_id='+ supervisor_id,
				    	displayField: 'name',
				    	preProcess: function(data) {
				    		return data;
				    	}
				    },
				    onSelect: function(item) {
				    	that.vm.getCity(item.value);
				    	setTimeout(function() {
				    		$(that.el).val('');
				    	}, 200);
				    }
				});
			}
		},

		vendortypeahead: {
			bind: function() {
				var that = this;
				$(this.el).typeahead({
				    ajax: {
				    	url: '/cms/api/vendor-of-supervisor?supervisor_id='+ supervisor_id,
				    	displayField: 'name',
				    	preProcess: function(data) {
				    		return data;
				    	}
				    },
				    onSelect: function(item) {
				    	that.vm.getVendor(item.value);

				    	setTimeout(function() {
				    		$(that.el).val('');
				    	}, 200);
				    }
				});
			}
		}
	},

	data: {
		selected_cities: [],
		selected_vendors: [],
		vendor_name: ''
	},

	computed: {
		cities_or_vendors: function() {
			if (this.selected_cities.length > 0 || this.selected_vendors.length > 0) return false;
			return true;
		}
	},

	watch: {
		
	},

	ready: function() {
		this.fetchVendors();
	},

	methods: {
		fetchVendors: function() {
			this.$http.get('/cms/api/fetch-vendors-by-staff/'+user_id, function(vendors) {
				this.$set('ex_vendors', vendors);
			});
		},

		getCity: function(id) {
			this.$http.get('/cms/cities/' + id, function(city) {
				if(this.selected_cities.length > 0)
				{
					var is_already_exist = false;
					for (var i = 0; i < this.selected_cities.length; i++) {
						if(this.selected_cities[i].id === city.id) {
							is_already_exist = true;
						}
					}
					
					if ( is_already_exist === false) {
						this.selected_cities.push(city);
					}
				} else {
					this.selected_cities.push(city);
				}
				
				// this.$set('cities', this.selected_cities);
			});
		},

		getVendor: function(id) {
			this.$http.get('/cms/vendors/' + id, function(vendor) {
				if(this.selected_vendors.length > 0)
				{
					var is_already_exist = false;
					for (var i = 0; i < this.selected_vendors.length; i++) {
						if(this.selected_vendors[i].id === vendor.id) {
							is_already_exist = true;
						}
					}
					
					if ( is_already_exist === false) {
						this.selected_vendors.push(vendor);
					}	
				} else {
					this.selected_vendors.push(vendor);
				}
				// this.$set('vendors', this.selected_vendors);
			});
		},

		removeCity: function(id) {
			for(var i = 0; i < this.selected_cities.length; i++) {
				var city = this.selected_cities[i];
				if (city.id == id) {
					this.selected_cities.$remove(city);
					break;
				}
			}
		},

		removeVendor: function(id) {
			for(var i = 0; i < this.selected_vendors.length; i++) {
				var vendor = this.selected_vendors[i];
				if (vendor.id == id) {
					this.selected_vendors.$remove(vendor);
					break;
				}
			}
		},

		setStaffForVendor: function() {
			var data = {
				staff_id: user_id,
				cities: this.selected_cities,
				vendors: this.selected_vendors
			}

			this.$http.patch('/cms/api/vendors/set-staff', data).success(function(response) {
				this.selected_vendors = [];
				this.selected_cities = [];
				this.fetchVendors();
				toastr.success('Success');
			}).error(function(error) {
				toastr.error(error);
			});
		},

		unsetStaff: function(id) {
			if(confirm("ကုန္သည္ကို ဖယ္ထုတ္မွာေသခ်ာပါသလား?")) {
				this.$http.get('/cms/api/vendors/unset-staff?staff_id='+user_id+'&vendor_id='+id).success(function(vendor) {
					this.fetchVendors();
					// toastr.success('Successfully remove vendor from this staff.')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},

		resetPassword: function() {
			if(confirm("Are you sure you want to reset password for this user?")) {
				this.$http.get('/cms/office/'+ user_id + '/reset-password').success(function(response) {
					toastr.success('Successfully reset password!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		}
	}
});