new Vue({
	el: '#customer',

	data: {
		newCustomer: {
			id: '',
			name: '',
			contact_person: '',
			address: '',
			city: '',
			country: '',
			zipcode: '',
			phone: '',
			email: '',
			files:[]
		},

		edit: false,
		imageUploadIndex:"",
		index: 0
	},

	computed: {
		errors: function() {
			if (! this.newCustomer['name']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchCustomers();
	},

	methods: {
		changeFileIndex: function(index){
	      this.fileUploadIndex = index;//get index
	      console.log("added index", index);
	    },

		uploadFile: function(event) {
	      	var files = event.target.files;
	      	for(var i = 0; i< files.length; i++){
	      		var file = files[i];
	      		if(!file.type.match('pdf'))
	        	continue;
	      		this.setupReader(file);
	    	}
	    },

	    setupReader: function(file) {
	        var vm = this;
	        var reader = new FileReader();
		        reader.onload = function(e) {
		         	var index = vm.fileUploadIndex;
		         	var customer_data = new Object();
	        		customer_data.name = file.name;
		         	customer_data.data = e.target.result;
		         	vm.newCustomer.files.push(customer_data);
		         	
		       		this.index += 1;
		       }
	        reader.readAsDataURL(file);
	    },

		fetchCustomers: function() {
			this.$http.get('/cms/export/customers?ajax=true', function(customers) {
				this.$set('customers', customers);
			});
		},

		createCustomer: function(){
			var customer = this.newCustomer;
			console.log(customer);
			this.newCustomer = {
				name: '',
				contact_person: '',
				address: '',
				city: '',
				country: '',
				zipcode: '',
				phone: '',
				email: ''
			};
			this.$http.post('/cms/export/customers', customer, function(data) {
				toastr.success('Successfully created new Customer.');
				this.fetchCustomers();
			});
		}, 

		showCustomer: function(id) {
			this.edit = true
			this.$http.get('/cms/export/customers/' + id, function(data) {
				this.newCustomer.id = data.id;
				this.newCustomer.name = data.name;
				this.newCustomer.contact_person = data.contact_person;
				this.newCustomer.address = data.address;
				this.newCustomer.city = data.city;
				this.newCustomer.country = data.country;
				this.newCustomer.zipcode = data.zipcode;
				this.newCustomer.phone = data.phone;
				this.newCustomer.email = data.email;
			});
		},

		editCustomer: function(id) {
			var customer = this.newCustomer;
			this.newCustomer = {
				id: '',
				name: '',
				contact_person: '',
				address: '',
				city: '',
				country: '',
				zipcode: '',
				phone: '',
				email: ''
			};

			this.$http.patch('/cms/export/customers/' + id, customer).success(function(response) {
				this.fetchCustomers();
				this.edit = false; 
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelCustomerEdit: function() {
			this.newCustomer = {
				id: '',
				name: '',
				contact_person: '',
				address: '',
				city: '',
				country: '',
				zipcode: '',
				phone: '',
				email: ''
			};
			this.edit = false;
		},

		deleteCustomer: function(id) {
			if(confirm("Are you sure you want to delete this Customer?")) {
				this.$http.delete('/cms/export/customers/'+ id).success(function(response) {
					this.fetchCustomers();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});