new Vue({
	el: '#cash-old',

	data: {
		newCash: {
			id: '',
			statement_id: '',
			remark: '',
			vendor_id : '',
			amount: ''
		},

		vendor: {},

		amount: 0,

	},

	computed: {
		errors: function() {
			if (!this.newCash['statement_id'] || !this.newCash['vendor_id'] || !this.newCash['amount'] || this.newCash['amount'] > this.amount) return true;
			return false;
		},

	},

	watch: {
		vendor_change: function(id) {
			if(id != 0) {
				this.newCash.vendor_id = id;
				this.fetchBankStatements(id);

				this.$http.get('/cms/vendors/'+id, function(vendor){
				this.vendor = vendor;
			});

			} else {
				response = []
				this.$set('invoices', response);
			}
		},

		statement_change: function(statement) {
			this.newCash.statement_id = statement.id;
			this.amount = parseInt(statement.remaining_amount) + parseInt(statement.amount);
			if (this.amount < parseInt(this.newCash.amount)) {
				this.newCash.amount = '';
			}
		},
	},

	ready: function() {
		this.fetchVendors();
	},

	methods: {

		fetchBankStatements: function(id){
			this.$http.get('/cms/api/banks-by-vendor/'+id, function(banks){
				this.$set('statements',banks);
			});
		},

		fetchVendors: function() {
			this.$http.get('/cms/api/fetch-vendors-by-staff', function(vendors) {
				this.$set('vendors',vendors);
			});
		},

		createCash: function(){
			if (this.newCash.amount > (this.vendor.opening - this.vendor.cash)) {
				$('#amount').focus();
				return alert('ေပးေခ်မည့္ေငြသည္ ကုန္သည္အေၾကြးထက္ မ်ားလုိ႕မရပါ။ စုိက္ေပးခ်င္လုိ႕လား :P');
			} 
			else {
				var cash = this.newCash;
				this.$http.post('/cms/cash-old-invoice', cash, function(data) {
					toastr.success('Successfully Cashed');
					window.location.href = "http://172.31.135.101/cms/invoices";
					this.clearCashInput();
				});
			}
		},

		clearCashInput: function() {
			this.newCash = {
				id: '',
				statement_id: '',
				remark: '',
				vendor_id : '',
				amount: 0
			};
			this.amount = 0;
			this.vendor = {};
		}
	}
});