"use: strict";

new Vue({
	el: '#vendor',

	data: {
		name: '',
		newVendor: {
			id: '',
			state_id: '',
			city_id: '',
			active: 1,
			is_cd: 0,
			name: '',
			address: '',
			phone: '',
			plans: [],
			invoice_limit: '',
			// grades: [],
			credits: []
		},

		targetsInfo: {
			vendor_id: '',
			year: '',
			season: '',
			plan: ''
		},

		vendorTargets: {
			agreed_amount: '',
			target_amount: ''
		},

		newTarget: {
			vendor_id: '',
			plan_id: '',
			year: '',
			season: '',
			month: '',
			target_amount: 0,
			agreed_amount: ''
		},

		state_change: '0',
		city_change: '0',
		invoices: [],
		plans: '',
		filter_city_id: 0,
		filter_state_id: 0,

		sortKey: '',

		reverse: 1,

		edit: false,

		pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },

		offset: 4,
		newOpening: {
			vendor_id: '',
			opening: '',
			cashi: '',
		}
	},

	watch: {
		state_change : function(id) {
			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.$set('cities',cities);
				this.newVendor.state_id = id;
				this.$nextTick(function() {
					this.city_change = this.newVendor.city_id;
				});
			});
		},

		city_change : function(id) {
			this.newVendor.city_id = this.city_change;
			// this.$http.get('/cms/api/vendors-in-city/' + id,function(vendors) {
			// 	this.$set('vendors',vendors);
			// });	
		},

		state_filter: function(id) {
			if (id == 0) return;
			else this.filter_state_id = id;
			
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});

			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.$set('cities_of_state',cities);
			});

			this.$http.get('/cms/api/vendors-in-state/' + id,function(vendors) {
				this.$set('vendors',vendors);
			});	

			$.LoadingOverlay("hide");
		},

		city_filter: function(id) {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});

			this.filter_city_id = id;
			// this.$remove('vendors');
			this.$http.get('/cms/api/vendors-in-city/' + id,function(vendors) {
				this.$set('vendors',vendors);
				$.LoadingOverlay("hide");
			});	
		},
	},

	computed: {
		got_targets: function() {
			if (this.targets.length > 0) return true;
			return false;
		},

		errors: function() {
			if (! this.newVendor['name']) return true;
			return false;
		},

		can_fetch_targets: function() {
			if(! this.targetsInfo['vendor_id'] || ! this.targetsInfo['year']) return true;
			return false;
		},

		can_submit_agreed_amount: function() {
			if(this.vendorTargets['agreed_amount'] || this.vendorTargets['target_amount']) return false;
			return true;
		},

		can_submit_new_target: function() {
			if(! this.newTarget['vendor_id'] || ! this.newTarget['plan_id'] || ! this.newTarget['year'] || ! this.newTarget['season'] || ! this.newTarget['month'] || ! this.newTarget['agreed_amount']) return true;
			return false;
		},

		can_add_new_opening: function() {
			if(! this.newOpening['vendor_id'] || ! this.newOpening['opening']) return true;
			return false;
		},		

		isActived: function () {
            return this.pagination.current_page;
        },
        
        pagesNumber: function () {
            if (!this.pagination.to) {
                return [];
            }
            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }

            return pagesArray;
        }
	},

	ready: function() {
		this.fetchVendors(this.pagination.current_page);
		this.fetchStates();
		this.fetchPlans();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states', function(states) {
				this.$set('states', states);
			});
		},

		sortBy: function(sortKey) {
			if (this.sortKey == sortKey) {
				this.reverse = this.reverse * (-1);
			}
			this.sortKey = sortKey;
		},

		fetchVendors: function(page) {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/vendors?page='+page, function(response) {
				this.$set('vendors', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});
		},

		changePage: function (page) {
            this.pagination.current_page = page;
            this.fetchVendors(page);
        },

		fetchPlans: function() {
			this.$http.get('/cms/plans', function(plans) {
                this.plans = plans;
			});	
		},

		createVendor: function(){
			var vendor = this.newVendor;
			this.newVendor = {
				city_id: '',
				state_id: '',
				name: '',
				address: '',
				active: 1,
				is_cd: 0,
				phone: '',
				plans: [],
				invoice_limit: '',
				credits: []
			};
			this.state_change = '0';
			this.city_change = '0';
			
			this.$http.post('/cms/vendors', vendor, function(data) {
				if (this.filter_city_id != 0) {
					this.$http.get('/cms/api/vendors-in-city/' + this.filter_city_id,function(vendors) {
						this.$set('vendors',vendors);
					});

				}
				else if(this.filter_state_id != 0) {
					 this.$http.get('/cms/api/cities-in-state?state_id=' + this.filter_state_id,function(cities) {
						this.$set('cities_of_state',cities);
					});

					this.$http.get('/cms/api/vendors-in-state/' + this.filter_state_id,function(vendors) {
						this.$set('vendors',vendors);
					});	
				}
				else{
					this.fetchVendors();
				}

				toastr.success('Successfully created new Vendor.');
			});
		}, 

		showVendor: function(id) {
			this.fetchPlans();
			this.edit = true;
			this.$http.get('/cms/vendors/' + id, function(data) {
				this.newVendor.id = data.id;
				this.newVendor.name = data.name;
				this.newVendor.state_id = data.city.state.id;
				this.newVendor.active = data.active;
				this.newVendor.is_cd = data.is_cd;
				this.newVendor.address = data.address;
				this.newVendor.phone = data.phone;
				this.newVendor.invoice_limit = data.invoice_limit;
				this.newVendor.city_id = data.city.id;
				// var grades = {};
				var credits = {};
				var plans = [];

				for (var i = 0; i < data.plans.length; i++) {
					// grades[data.plans[i].pivot.plan_id] = data.plans[i].pivot.grade_id;
					credits[data.plans[i].pivot.plan_id] = data.plans[i].pivot.credit;
					plans.push(data.plans[i].id);
				};

				this.newVendor.credits = credits;	
				// this.newVendor.grades = grades;
				this.newVendor.plans = plans;
				
				if (this.state_change == data.city.state.id) {
					this.city_change = data.city.id;
				}

				this.state_change = data.city.state.id;
			});
		},

		editVendor: function(id) {
			var vendor = this.newVendor;
			this.newVendor = {
				id: '',
				state_id: '',
				city_id: '',
				name: '',
				address: '',
				phone: '',
				invoice_limit: '',
				plans: [],
				credits: []
			};

			this.$http.patch('/cms/vendors/' + id, vendor).success(function(response) {
				// this.fetchVendors();
				this.edit = false;
				// this.$set('state_change', '0');
				// this.$set('cities', []);
				// this.$set('city_change', '0');
				if (this.filter_city_id != 0) {
						this.$http.get('/cms/api/vendors-in-city/' + this.filter_city_id,function(vendors) {
							this.$set('vendors',vendors);
						});	
					}
					else if(this.filter_state_id != 0) {
						 this.$http.get('/cms/api/cities-in-state?state_id=' + this.filter_state_id,function(cities) {
							this.$set('cities_of_state',cities);
						});

						this.$http.get('/cms/api/vendors-in-state/' + this.filter_state_id,function(vendors) {
							this.$set('vendors',vendors);
						});	
					}
					else{
						this.fetchVendors();
					}
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelVendorEdit: function() {
			this.newVendor = {
				id: '',
				city_id: '0',
				state_id: '0',
				name: '',
				address: '',
				phone: '',
				invoice_limit: '',
				plans: [],
			};
			this.$set('state_change', '0');
			this.$set('cities', []);
			this.$set('city_change', '0');
			this.edit = false;
		},

		deleteVendor: function(id) {
			if(confirm("Are you sure you want to delete this Vendor?")) {
				this.$http.delete('/cms/vendors/'+ id).success(function(response) {
					if (this.filter_city_id != 0) {
						this.$http.get('/cms/api/vendors-in-city/' + this.filter_city_id,function(vendors) {
							this.$set('vendors',vendors);
						});	
					}
					else if(this.filter_state_id != 0) {
						 this.$http.get('/cms/api/cities-in-state?state_id=' + this.filter_state_id,function(cities) {
							this.$set('cities_of_state',cities);
						});

						this.$http.get('/cms/api/vendors-in-state/' + this.filter_state_id,function(vendors) {
							this.$set('vendors',vendors);
						});	
					}
					else{
						this.fetchVendors();
					}
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error);
				});
			}
		},

		showTarget: function(vendor) {
			$("#vendor-target .modal-title").html(vendor.name);
			this.targetsInfo.vendor_id = vendor.id;
			$('#vendor-target').modal({
				backdrop: 'static',
				keyboard: false
			});
		},

		hideTarget: function() {
			$('#vendor-target').modal('hide');
			this.$set('targets', '');
		},

		addTarget: function(vendor) {
			$("#add-vendor-target .modal-title").html(vendor.name);
			this.newTarget.vendor_id = vendor.id;
			$('#add-vendor-target').modal('show');	
		},

		addOpening: function(vendor) {
			$("#add-vendor-opening .modal-title").html(vendor.name);
			this.newOpening.vendor_id = vendor.id;
			this.newOpening.cash = vendor.cash;
			this.newOpening.opening = vendor.opening;
			$('#add-vendor-opening').modal('show');	
		},

		createOpening: function() {
			var data = this.newOpening;
			console.log(data.opening);
			console.log(data.cash);
			if (data.opening < data.cash) {
				$('#opening').focus();
				return alert('Opening သည္ ေပးေခ်ထားေသာ ေငြထက္ နည္းေနပါသည္');
			}

			if (confirm("Are you sure!")) {
				this.$http.patch('/cms/api/vendors/opening', data).success(function(response) {
					this.newOpening = {
						vendor_id: '',
						opening: 0,
						cash: 0
					};
					$('#add-vendor-opening').modal('hide');
					toastr.success('Opening amount သတ်မှတ်ပီးပါပီ');
					this.fetchVendors();
				}).error(function(error) {
					$('#add-vendor-opening').modal('hide');
					toastr.error(error.message);
				});
			}
		},

		createTarget: function() {
			var data = this.newTarget;
			// /cms/api/vendors/targets/create
			if (confirm("Are you sure! Agreed amount cannot edit(update) after created.")) {
				this.$http.post('/cms/api/targets/create', data).success(function(response) {
					toastr.success('Agreed amount သတ်မှတ်ပီးပါပီ');
				}).error(function(error) {
					toastr.error(error.message);
				});
			}
		},

		fetchVendorTargets: function() {
			var data = this.targetsInfo;
			this.$http.get('/cms/api/vendors/' + data.vendor_id + '/targets?year='+data.year+'&season='+data.season+'&plan='+data.plan,function(targets) {
				this.$set('targets',targets);
			});
		},

		submitAgreedTarget: function(id) {
			var data = this.vendorTargets;
			this.vendorTargets = {
				target_amount: '',
				agreed_amount: ''
			}
			if(confirm("Are you sure! Agreed amount cannot edit(update) after created.")) {
				this.$http.patch('/cms/api/targets/'+id+'/agreed-amount', data).success(function(response) {
					this.fetchVendorTargets();
					toastr.success('Agreed amount သတ္မွတ္ပီးပါပီ။');
				}).error(function(error) {
					toastr.error(error);
				});
			};
		},

		deleteTarget: function(id) {
			if(confirm("Are you sure!")) {
				this.$http.delete('/cms/api/targets/'+id+'/delete').success(function(response) {
					this.fetchVendorTargets();
					toastr.success('Successfully deleted');
				}).error(function(error) {
					toastr.error(error);
				});
			};
		},

		activation: function(id) {
			if (id != 0 && confirm("Are you sure!")) {
				this.$http.patch('/cms/api/vendors/activation/'+id).success(function(response) {
				if (this.filter_city_id != 0) {
					this.$http.get('/cms/api/vendors-in-city/' + this.filter_city_id,function(vendors) {
						this.$set('vendors',vendors);
					});	
				}
				else if(this.filter_state_id != 0) {
					 this.$http.get('/cms/api/cities-in-state?state_id=' + this.filter_state_id,function(cities) {
						this.$set('cities_of_state',cities);
					});

					this.$http.get('/cms/api/vendors-in-state/' + this.filter_state_id,function(vendors) {
						this.$set('vendors',vendors);
					});	
				}
				else{
					this.fetchVendors();
				}
					toastr.success('Successfully updated!');
				}).error(function(error) {
					toastr.error(error);
				});
			}
		},

		cd: function(id) {
			if (id != 0 && confirm("Are you sure!")) {
				this.$http.patch('/cms/api/vendors/cd/'+id).success(function(response) {
				if (this.filter_city_id != 0) {
					this.$http.get('/cms/api/vendors-in-city/' + this.filter_city_id,function(vendors) {
						this.$set('vendors',vendors);
					});	
				}
				else if(this.filter_state_id != 0) {
					this.$http.get('/cms/api/cities-in-state?state_id=' + this.filter_state_id,function(cities) {
						this.$set('cities_of_state',cities);
					});

					this.$http.get('/cms/api/vendors-in-state/' + this.filter_state_id,function(vendors) {
						this.$set('vendors',vendors);
					});	
				}
				else{
					this.fetchVendors();
				}
					toastr.success('Successfully updated!');
				}).error(function(error) {
					toastr.error(error);
				});
			}
		}
	}
});