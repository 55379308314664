new Vue({
	el: '#assign-invoice',

	data: {
		to_cash: {
			statement_id: '',
			checkedInvoices: []
		},
		vendor_change: 0,
		check: [],
		total: 0,
		amount: 0,
		overflow: false,
		filter_vendor: '',
		plan_disable: true,
		plan_change: 0,
		cash_history_invoice: '',
		staff: 0,
		plan_id: 0,
		vendor: 0
	},

	computed: {
		errors: function() {
			if (!this.to_cash['statement_id'] || this.to_cash['checkedInvoices'].length < 1) return true;
			return false;
		},
	},

	ready: function() {
		this.fetchVendors();
		this.fetchBanks();
		this.fetchPlans();
		this.fetchStaffs();
	},

	watch: {
		reload: function(value) {
			if (value == 1 && this.filter_vendor != 0) {
				this.fetchBankStatements(this.vendor_change);
			}
		},

		//Filter with dedicated staff
		staff_filter: function(staff) {
			this.staff = staff;
			var request;
			
			if(staff != 0) request = request + '&staff='+staff;
			if(this.vendor > 0) request = request + '&vendor=' + this.vendor;
			//If plan
			if(this.plan_id > 0) request = request + '&plan_id=' + this.plan_id;

			this.$http.get('/cms/api/fetch-vendors-by-staff/'+staff+'?ajax=true', function(vendors) {
				this.$set('vendors',vendors);
			});
		},


		vendor_change: function(id) {
			if(id != 0) {
				this.filter_vendor = id;
				this.plan_disable = false;
				this.$http.get('/cms/api/get-approved-invoices-by-vendor/'+id,function(response) {
					this.$set('invoices', response);
					this.fetchBankStatements(id);
					this.fetchPlans();
				});	
			} else {
				response = []
				this.$set('invoices', response);
			}
		},

		plan_change: function(id) {
			if (id != 0) {
				this.$http.get('/cms/api/get-approved-invoices-by-vendor-and-plan/'+id+'/'+this.filter_vendor,function(response) {
					this.$set('invoices', response);
					this.fetchBankStatements(this.vendor_change);
				});	
			}
		},


		statement_change: function(statement) {
			this.to_cash.statement_id = statement.id;
			this.amount = parseInt(statement.remaining_amount) + parseInt(statement.amount);
			if (this.amount < this.total) {
				this.overflow = true;
			}
		},

		check: function(invoice) {
			this.to_cash.checkedInvoices = [];
			this.total = 0;
			for (var i = invoice.length - 1; i >= 0; i--) {
				this.to_cash.checkedInvoices.push(invoice[i]['id']);
				this.total += invoice[i]['total'] - invoice[i]['deposit'];
			}
			if (this.amount < this.total) {
				this.overflow = true;
			}
			else {
				this.overflow = false;
			}
		}
	},

	methods: {
		// fetchBanks: function() {
		// 	this.$http.get('/cms/banks', function(banks) {
		// 		console.log(banks);
		// 		this.$set('banks', banks);
		// 	});
		// },

		//Fetch staffs to filter invoices list
		fetchStaffs: function() {
			this.$http.get('/cms/office/staffs?ajax=true', function(staffs) {
				this.$set('staffs', staffs);
			});
		},

		fetchVendors: function() {
			this.$http.get('/cms/api/fetch-vendors-by-staff/'+current_user_id+'?ajax=true', function(vendors) {
				this.$set('vendors',vendors);
			});
		},

		fetchBankStatements: function(id){
			this.$http.get('/cms/api/banks-by-vendor/'+id, function(banks){
				this.$set('statements',banks);
			});
		},

		fetchPlans: function(){
			this.$http.get('/cms/plans', function(plans){
				this.$set('plans',plans);
			});
		},

		showCashHistory: function(id) {
			$('#cash_history').modal('show');
			this.$http.get('/cms/invoices/' + id, function(data) {
				this.cash_history_invoice = data;
			});	
		},

		assignToCash: function() {
			var to_cash = this.to_cash;
			this.to_cash = {
				statement_id: '',
				checkedInvoices: []
			},
			this.vendor_change = 0;
			this.$http.post('/cms/assign-to-cash', to_cash, function(data) {
				toastr.success('Successfully assigned.');
				this.vendor_change = 0;
				this.fetchVendors();
				this.total = 0;
				this.amount= 0;
				this.overflow = false;
				this.filter_vendor = '';
				this.fetchPlans();
				this.plan_disable = true;
				this.fetchVendors();
				this.fetchBanks();
			});
		},
	}
});