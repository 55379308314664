new Vue({
	el: '#expense_category',

	data: {
		newCategory: {
			id: '',
			parent_id: 0,
			title: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (!this.newCategory['title']) return true;
			return false;
		}
	},

	watch: {
		// state_change : function(id) {
		// 	this.newCity['state_id'] = id;
		// 	this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
		// 		this.$set('cities_by_state',cities);
		// 	});
		// },

		// state_filter: function(id) {
		// 	if (id != 0) {
		// 		this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
		// 			this.$set('cities',cities);
		// 		});	
		// 	}
		// }
	},


	ready: function() {
		this.fetchCategories();
	},

	methods: {
		fetchCategories: function() {
			this.$http.get('/cms/expenses-categories', function(categories) {
				this.$set('categories', categories);
				console.log(categories);
			});
		},

		createCategory: function(){
			var category = this.newCategory;
			this.newCategory = {
				parent_id: 0,
				title: ''
			},

			this.$http.post('/cms/expenses-categories', category, function(data) {
				toastr.success('Successfully created new category.');
				this.fetchCategories();
			});
		}, 

		showCategory: function(id) {
			this.edit = true
			this.$http.get('/cms/expenses-categories/' + id, function(data) {
				this.newCategory.id = data.id;
				this.newCategory.title = data.title;
				this.newCategory.parent_id = data.parent_id;
			});
		},

		editCategory: function(id) {
			var category = this.newCategory;
			this.newCategory = {
				parent_id: 0,
				title: '',
				id:''
			},

			this.$http.patch('/cms/expenses-categories/' + id, category).success(function(response) {
				this.fetchCategories();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.error(error);
			});
		},

		cancelCategoryEdit: function() {
			this.newCategory = {
				parent_id: 0,
				title: '',
				id:''
			},
			this.edit = false;
		},

		deleteCategory: function(id) {
			if(confirm("Are you sure you want to delete this category?")) {
				this.$http.delete('/cms/expenses-categories/'+ id).success(function(response) {
					this.fetchCategories();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});

new Vue({
	el: '#locations',

	data: {
		newLocation: {
			id: '',
			name: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (!this.newLocation['name']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchLocations();
	},

	methods: {
		fetchLocations: function() {
			this.$http.get('/cms/locations', function(locations) {
				this.$set('locations', locations);
			});
		},

		createLocation: function(){
			var location = this.newLocation;
			this.newLocation = {
				name: ''
			},

			this.$http.post('/cms/locations', location, function(data) {
				toastr.success('Successfully created new location.');
				this.fetchLocations();
			});
		}, 

		showLocation: function(id) {
			this.edit = true
			this.$http.get('/cms/locations/' + id, function(data) {
				this.newLocation.id = data.id;
				this.newLocation.name = data.name;
			});
		},

		editLocation: function(id) {
			var location = this.newLocation;
			this.newLocation = {
				name: '',
				id:''
			},

			this.$http.patch('/cms/locations/' + id, location).success(function(response) {
				this.fetchLocations();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.error(error);
			});
		},

		cancelLocationEdit: function() {
			this.newLocation = {
				name: '',
				id:''
			},
			this.edit = false;
		},

		deleteLocation: function(id) {
			if(confirm("Are you sure you want to delete this location?")) {
				this.$http.delete('/cms/locations/'+ id).success(function(response) {
					this.fetchLocations();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});


new Vue({
	el: '#expense',

	data: {
		newExpense: {
			id: '',
			title: '',
			amount: '',
			category_id: '',
			location_id: '',
			date: ''
		},

		filter: {
			location_id: 0,
			category_id: 0,
			from: '',
			to: ''
		},

		pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },

        offset: 4,

		edit: false
	},

	computed: {
		errors: function() {
			if (!this.newExpense['title'] || !this.newExpense['amount'] || !this.newExpense['date']) return true;
			return false;
		},

		isActived: function () {
            return this.pagination.current_page;
        },
        
        pagesNumber: function () {
            if (!this.pagination.to) {
                return [];
            }
            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }

            return pagesArray;
        }
	},

	ready: function() {
		this.fetchLocations(this.pagination.current_page);
		this.fetchCategories();
		this.fetchExpenses();
	},

	methods: {
		numberWithCommas: function(x) {
		    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},

		fetchLocations: function() {
			this.$http.get('/cms/locations', function(locations) {
				this.$set('locations', locations);
			});
		},

		fetchCategories: function() {
			this.$http.get('/cms/expenses-categories?filter=all', function(categories) {
				this.$set('categories', categories);
			});
		},

		fetchExpenses: function(page) {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});

			this.$http.get('/cms/expenses?page='+page, function(expenses) {
				this.$set('expenses', expenses.data);
				this.$set('pagination', expenses.pagination);
				$.LoadingOverlay("hide");
			});
		},

		createExpense: function(){
			var expense = this.newExpense;
			this.newExpense = {
				title: '',
				amount: '',
				category_id: '',
				location_id: '',
				date: ''
			},

			this.$http.post('/cms/expenses', expense, function(data) {
				toastr.success('Successfully created new expense.');
				this.fetchExpenses();
			});
		}, 

		showExpense: function(id) {
			this.edit = true
			this.$http.get('/cms/expenses/' + id, function(data) {
				this.newExpense.id = data.id;
				this.newExpense.title = data.title;
				this.newExpense.amount = data.amount;
				this.newExpense.category_id = data.category_id;
				this.newExpense.location_id = data.location_id;
				this.newExpense.date = data.date;
			});
		},

		editExpense: function(id) {
			var expense = this.newExpense;
			this.newExpense = {
				id:'',
				title: '',
				amount: '',
				category_id: '',
				location_id: '',
				date: ''
			},

			this.$http.patch('/cms/expenses/' + id, expense).success(function(response) {
				this.fetchExpenses();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.error(error);
			});
		},

		cancelExpenseEdit: function() {
			this.newExpense = {
				id:'',
				title: '',
				amount: '',
				category_id: '',
				location_id: '',
				date: ''
			},
			this.edit = false;
		},

		deleteExpense: function(id) {
			if(confirm("Are you sure you want to delete this expense?")) {
				this.$http.delete('/cms/expenses/'+ id).success(function(response) {
					this.fetchExpenses();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},

		getFilteredExpenses: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			
			var request = '?page=1';

			this.pagination = {
	            total: 0,
	            per_page: 7,
	            from: 1,
	            to: 0,
	            current_page: 1
	        }

			if(this.filter.from != '') request = request + '&from=' + this.filter.from;
			if(this.filter.to != '') request = request + '&to=' + this.filter.to;
			if(this.filter.category_id != 0) request = request + '&category_id='+this.filter.category_id;
			if(this.filter.location_id != 0) request = request + '&location_id='+this.filter.location_id;

			this.$http.get('/cms/expenses'+request, function(response) {
				this.$set('expenses', response.data);
				this.$set('total', response.total);
				$.LoadingOverlay("hide");
			});
		},

		downloadExcel: function() {
			var request = '?q=download';

			if(this.filter.from != '') request = request + '&from=' + this.filter.from;
			if(this.filter.to != '') request = request + '&to=' + this.filter.to;
			if(this.filter.category_id != 0) request = request + '&category_id='+this.filter.category_id;
			if(this.filter.location_id != 0) request = request + '&location_id='+this.filter.location_id;

			window.location.href = '/cms/expenses/export'+request;
		},

		changePage: function (page) {
            this.pagination.current_page = page;
            this.fetchExpenses(page);
        }
	}
});

