new Vue({
	el: '#order',

	directives: {
		ordertypeahead: {
			bind: function() {
				var that = this;
				$(this.el).typeahead({
					scrollBar: true,
				    ajax: {
				    	url: '/cms/export/product/search',
				    	displayField: 'title',
				    	preProcess: function(data) {
				    		console.log(data);
				    		var toRemove = [];
				    		for (var i = 0; i < data.length; i++) {
				    			var ids = data[i].id.split('|');
				    			if (that.vm.addedProducts[ids[0]] && that.vm.addedProducts[ids[0]] == 1) {
				    				data.splice(i, 1);
				    				i--;
				    			}
				    		};
				    		return data;
				    	}
				    },
				    onSelect: function(item) {
				    	var ids = item.value.split('|');
				    	that.vm.fetchProductDetails(ids[0], true, -1);
				    	setTimeout(function() {
				    		$(that.el).val('');
				    	}, 200);
				    }
				});
			}
		}
	},

	data: {
		addedProducts: {},
		productDetails: {},
		productsList: [],
		detailsToShow: [],

		pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },

		offset: 4,
	},

	computed: {
		errors: function() {
			if (this.productsList.length == 0) return true;
			return false;
		},

		isActived: function () {
            return this.pagination.current_page;
        },
        
        pagesNumber: function () {
            if (!this.pagination.to) {
                return [];
            }
            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }

            return pagesArray;
        }
	},

	ready: function() {
		this.fetchOrders(this.pagination.current_page);
		this.fetchCustomers();
		this.fetchProducts();
	},

	methods: {

		fetchProductDetails: function(id,toAdd, productIndex) {
			this.$http.get('/cms/export/products/' + id, function(productDetails) {
				var newProductDetails = productDetails;
				if (toAdd) {
					this.productsList.push(newProductDetails);
					this.addedProducts[newProductDetails.id] = 1;
				}

				this.productDetails = newProductDetails;

				// this.renderProductDetails();

				if (productIndex == -1) {
					this.currentProductIndex = this.productsList.length - 1;
				} else {
					this.currentProductIndex = productIndex;
				}
			});

		},

		fetchOrders: function(page) {
			this.$http.get('/cms/export/orders?page='+page,function(response) {
				this.$set('orders', response.data);
				this.$set('pagination', response.pagination);
				$.LoadingOverlay("hide");
			});
		},

		fetchCustomers: function() {
			this.$http.get('/cms/export/customers/?ajax=true', function(customers) {
				this.$set('customers',customers);
			});
		},

		fetchProducts: function() {
			this.$http.get('/cms/export/products/?ajax=true', function(products) {
				this.$set('products',products);
			});
		},

		removeProduct: function(index) {
			var product = this.productsList[index];
			this.productsList.splice(index, 1);
			this.addedProducts[product.id] = 0;
		},

		changePage: function (page) {
            this.pagination.current_page = page;
            this.fetchOrders(page);
        },

        deleteOrder(id) {
			if(confirm("Are you sure you want to delete this Order?")) {
				this.$http.delete('/cms/export/orders/'+ id).success(function(response) {
					this.fetchOrders(this.pagination.current_page);
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error);
				});
			}
		},
	}
});