new Vue({
	el: '#branches',

	data: {
		newBranch: {
			id: '',
			state_id: '',
			name: ''
		},

		edit: false
	},

	computed: {
		new_branch_errors: function() {
			if (!this.newBranch['name'] || !this.newBranch['state_id']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchStates();
		this.fetchBranches();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states?ajax=true', function(states) {
				this.$set('states', states);
			});
		},

		fetchBranches: function() {
			this.$http.get('/cms/branches?ajax=true', function(branches) {
				this.$set('branches', branches);
			});	
		},

		createBranch: function(){
			var branch = this.newBranch;
			this.newBranch = {
				state_id: '',
				name: ''
			},

			this.$http.post('/cms/branches', branch, function(data) {
				toastr.success('Successfully created new city.');
				this.fetchBranches();
			});
		},

		showBranch: function(id) {
			this.edit = true
			this.$http.get('/cms/branches/' + id, function(data) {
				this.newBranch.id = data.id;
				this.newBranch.name = data.name;
				this.newBranch.state_id = data.state_id;
			});
		},

		editBranch: function(id) {
			var branch = this.newBranch;
			this.newBranch = {
				id: '',
				state_id: '',
				name: ''
			};

			this.$http.patch('/cms/branches/' + id, branch).success(function(response) {
				this.fetchBranches();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.error(error);
			});
		},

		cancelBranchEdit: function() {
			this.newBranch = {
				id: '',
				state_id: '',
				name: ''
			};
			this.edit = false;
		},

		deleteBranch: function(id) {
			if(confirm("Are you sure you want to delete this Branch?")) {
				this.$http.delete('/cms/branches/'+ id).success(function(response) {
					this.fetchBranches();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		}
	}
});