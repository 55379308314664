new Vue({
	el: '#message',

	data: {
		newMessage: {
			id: '',
			type: '',
			message: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newMessage['message']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchMessages();
	},

	methods: {
		fetchMessages: function() {
			this.$http.get('/cms/messages?ajax=true', function(messages) {
				this.$set('messages', messages);
			});
		},

		createMessage: function(){
			var message = this.newMessage;
			this.newMessage = {
				type: '',
				message: ''
			},
			this.$http.post('/cms/messages', message, function(data) {
				toastr.success('Successfully created new message.');
				this.fetchMessages();
				$('#add_message').modal('hide');
			});
		}, 

		showMessage: function(id) {
			$('#add_message').modal('show');
			this.edit = true
			this.$http.get('/cms/messages/' + id, function(data) {
				this.newMessage.id = data.id
				this.newMessage.type = data.type
				this.newMessage.message = data.message
			});
		},

		editMessage: function(id) {
			var message = this.newMessage;
			this.newMessage = {
				id: '',
				type: '',
				message: ''
			};

			this.$http.patch('/cms/messages/' + id, message).success(function(response) {
				this.fetchMessages();
				this.edit = false;
				toastr.success('Successfully updated!');
				$('#add_message').modal('hide');
			}).error(function(error) {
				toastr.success(error);
				$('#add_message').modal('hide');
			});
		},

		cancelMessageEdit: function() {
			this.newMessage = {
				id: '',
				name: ''
			};
			this.edit = false;
			$('#add_message').modal('hide');
		},

		deleteMessage: function(id) {
			if(confirm("Are you sure you want to delete this Message?")) {
				this.$http.delete('/cms/messages/'+ id).success(function(response) {
					this.fetchMessages();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});