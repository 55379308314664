new Vue({
	el: '#product',

	data: {
		name: '',
		newProduct: {
			id: '',
			name: '',
			short_key: '',
			plan_id: '',
			type_id: '',
			box_dozen: '',
			bag_dozen: '',
			prices: [
	        {
	          id:"",
	          type:"",
	          price:"",
	          last_date:""
	        }
	      ]
		},

		//Plan Id to fetch products after updated
		plan_id: '',


		pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },

		offset: 4,

		sortKey: '',

		reverse: 1,

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newProduct['name']) return true;
			return false;
		},

		isActived: function () {
            return this.pagination.current_page;
        },

        pagesNumber: function () {
            if (!this.pagination.to) {
                return [];
            }
            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }

            return pagesArray;
        }
	},

	watch: {
		plan_change: function(plan) {
			this.plan_id = plan;
			if(plan != 0 && plan != "all") {
				this.$http.get('/cms/api/products-in-plan?plan_id='+plan,function(response) {
					this.$set('products', response);
					this.pagination = {
						total: 0,
			            per_page: 7,
			            from: 1,
			            to: 0,
			            current_page: 1
					}
				});	
			} else {
				this.fetchProducts();
			}
		}
	},

	ready: function() {
		this.fetchProducts(this.pagination.current_page);
		this.fetchPlans();
		this.fetchTypes();
	},

	methods: {
		fetchProducts: function(page) {
			this.$http.get('/cms/products?page='+page, function(response) {
				this.$set('products', response.data);
				this.$set('pagination', response.pagination);
			});
		},

		addNewPrice: function(){
	      var newPrice = {
	        type:"",
	        price:"",
	        last_date:""
	      };
	      this.newProduct.prices.push(newPrice);
	    },


		sortBy: function(sortKey) {
			if (this.sortKey == sortKey) {
				this.reverse = this.reverse * (-1);
			}
			this.sortKey = sortKey;
		},

		changePage: function (page) {
            this.pagination.current_page = page;
            this.fetchProducts(page);
        },

		fetchPlans: function() {
			this.$http.get('/cms/plans', function(plans) {
				this.$set('plans', plans);
			});
		},

		fetchTypes: function() {
			this.$http.get('/cms/types', function(types) {
				this.$set('types', types);
			})
		},

		// createProduct: function(event){
		// 	var product = this.newProduct;
		// 	console.log(product.prices);
		// 	this.newProduct = {};
		// 	this.$http.post('/cms/products', product, function(data) {
		// 		toastr.success('Successfully created new product.');
		// 		this.fetchProducts();
		// 	});
		// }, 

		// showProduct: function(id) {
		// 	this.edit = true;
		// 	this.$http.get('/cms/products/' + id, function(result_product) {
		// 		this.newProduct = result_product;
		// 		console.log(this.newProduct.prices[1].price);
		// 	});
		// },

		// editProduct: function(event, id) {
		// 	event.preventDefault();
		// 	var product = this.newProduct;
		// 	this.newProduct = {};

		// 	this.$http.patch('/cms/products/' + id, product).success(function(response) {
		// 		this.fetchProducts();
		// 		this.edit = false;
		// 		toastr.success('Successfully updated!');
		// 	}).error(function(error) {
		// 		toastr.success(error);
		// 	});
		// },

		createProduct: function(event){
			var product = this.newProduct;
			console.log(product.prices);
			this.newProduct = {
				name: '',
				plan_id: '',
				type_id: '',
				box_dozen: '',
				bag_dozen: '',
				prices: [
		        {
		          id:"",
		          type:"",
		          price:"",
		          last_date:""
		        }
		      ]
			},
			this.$http.post('/cms/products', product, function(data) {
				toastr.success('Successfully created new product.');
				this.fetchProducts();
			});
		}, 

		showProduct: function(id) {
			this.edit = true;
			this.$http.get('/cms/products/' + id, function(result_product) {
				this.newProduct = result_product;
				// console.log(this.newProduct.prices[1].price);
			});
		},

		editProduct: function(event, id) {
			event.preventDefault();
			var product = this.newProduct;
			this.newProduct = {
				id: '',
				name: '',
				plan_id: '',
				type_id: '',
				box_dozen: '',
				bag_dozen: '',
				prices: [
		        {
		          id:"",
		          type:"",
		          price:"",
		          last_date:""
		        }
		      ]
			};
			this.$http.patch('/cms/products/' + id, product).success(function(response) {

				if(this.plan_id != 0 && this.plan_id != "all") {
					this.$http.get('/cms/api/products-in-plan?plan_id='+this.plan_id,function(response) {
						this.$set('products', response);
						this.pagination = {
							total: 0,
				            per_page: 7,
				            from: 1,
				            to: 0,
				            current_page: 1
						}
					});	
				} else {
					this.fetchProducts();
				}

				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelProductEdit: function() {
			this.newProduct = {
				id: '',
				name: '',
				plan_id: 0,
				type_id: '',
				box_dozen: '',
				bag_dozen: '',
				prices: [
		        {
		          id:"",
		          type:"",
		          price:"",
		          last_date:""
		        }
		      ]
			};
			this.edit = false;
		},

		deleteProduct: function(id) {
			if(confirm("Are you sure you want to delete this Product?")) {
				this.$http.delete('/cms/products/'+ id).success(function(response) {
					this.fetchProducts();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});

