new Vue({
	el: '#permission',

	data: {
		newPermission: {
			id: '',
			name: '',
			label: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newPermission['name'] || ! this.newPermission['label']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchPermissions();
	},

	methods: {
		fetchPermissions: function() {
			this.$http.get('/cms/permissions?ajax=true', function(permissions) {
				this.$set('permissions', permissions);
			});
		},

		createPermission: function(){
			var permission = this.newPermission;
			this.newPermission = {name: '', label: ''};
			this.$http.post('/cms/permissions', permission);
			this.fetchPermissions();
			toastr.success('Successfully created new permission.')
		}, 

		showPermission: function(id) {
			this.edit = true
			this.$http.get('/cms/permissions/' + id, function(data) {
				this.newPermission.id = data.id
				this.newPermission.name = data.name
				this.newPermission.label = data.label
			});
		},

		editPermission: function(id) {
			var permission = this.newPermission;
			this.newPermission = {id: '', name: '', label: ''};
			this.$http.patch('/cms/permissions/' + id, permission).success(function(response) {
				this.fetchPermissions();
				this.edit = false;
				toastr.success('Successfully updated!')
			}).error(function(error) {
				toastr.success(error)
			});
		},

		cancelPermissionEdit: function() {
			this.newPermission = {id: '', name: '', label: ''};
			this.edit = false;
		},

		deletePermission: function(id) {
			if(confirm("Are you sure you want to delete this permission?")) {
				this.$http.delete('/cms/permissions/'+ id).success(function(response) {
					this.fetchPermissions();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});