new Vue({
	el: '#city',

	data: {
		name: '',
		newCity: {
			id: '',
			state_id: '',
			group_parent_id: '',
			is_group: '',
			name: '',
			short_key:'',
			credits: []
		},

		state_change: '',

		edit: false
	},

	computed: {
		errors: function() {
			if (!this.newCity['name'] || !this.newCity['state_id'] || !this.newCity['short_key']) return true;
			return false;
		},

		is_group: function() {
			if(this.newCity['is_group']) {
				this.newCity.group_parent_id = 0;
				return true;
			} else {
				return false;
			}
		}
	},

	watch: {
		state_change : function(id) {
			this.newCity['state_id'] = id;
			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.$set('cities_by_state',cities);
			});
		},

		state_filter: function(id) {
			if (id != 0) {
				this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
					this.$set('cities',cities);
				});	
			}
		}
	},


	ready: function() {
		this.fetchStates();
		this.fetchCities();
		this.fetchPlans();
	},

	methods: {
		fetchCities: function() {
			this.$http.get('/cms/cities?ajax=true', function(cities) {
				this.$set('cities', cities);
			});
		},

		fetchStates: function() {
			this.$http.get('/cms/states?ajax=true', function(states) {
				this.$set('states', states);
			});
		},

		fetchPlans: function() {
			this.$http.get('/cms/plans?ajax=true', function(plans) {
				this.$set('plans', plans);
				var plans = [];
				for (var i = 1; i <= plans.length; i++) {
					plans.push(data.plans[i].id);
				};
				this.newCity.credits = plans;
			});
		},

		createCity: function(){
			var city = this.newCity;
			this.newCity = {
				state_id: '',
				group_parent_id: '',
				is_group: '',
				name: '',
				short_key: '',
				credits: []
			},
			this.state_change = '0';
			this.$http.post('/cms/cities', city, function(data) {
				toastr.success('Successfully created new city.');
				this.fetchCities();
			});
		}, 

		showCity: function(id) {
			this.edit = true
			this.$http.get('/cms/cities/' + id, function(data) {
				this.newCity.id = data.id;
				this.state_change = data.state_id;
				this.newCity.name = data.name;
				this.newCity.state_id = data.state_id;
				this.newCity.short_key = data.short_key;
				
				if (undefined != data.plans) {
					var credits = {};
					for (var i = 0; i < data.plans.length; i++) {
						credits[data.plans[i].pivot.plan_id] = data.plans[i].pivot.credit;
					};
					this.newCity.credits = credits;	
				}
			});
		},

		editCity: function(id) {
			var city = this.newCity;
			this.newCity = {
				id: '',
				state_id: city.state_id,
				name: '',
				short_key: '',
				credits:[]
			};

			this.$http.patch('/cms/cities/' + id, city).success(function(response) {
				this.fetchCities();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.error(error);
			});
		},

		cancelCityEdit: function() {
			this.newCity = {
				id: '',
				state_id: city.state_id,
				name: '',
				short_key: '',
				credits:[]
			};
			this.edit = false;
		},

		deleteCity: function(id) {
			if(confirm("Are you sure you want to delete this City?")) {
				this.$http.delete('/cms/cities/'+ id).success(function(response) {
					this.fetchCities();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});