var QuestionVM = new Vue({
	el: '#question',

	data: {
		newQuestion: {
			id: '',
			question: '',
			marks: 0,
			tags: '',
			question_type: 0,
			department_id: 0
		},
		questionText: '',
		showMC: false,

		newSearch: {
			tf: '',
			mc: '',
			qa: '',
			dept: 0
		},

		showPrint: false,

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newQuestion['question'] || (this.newQuestion['department_id'] == 0 || this.newQuestion['department_id'] == '0')) return true;
			return false;
		}
	},

	watch: {
			questionText: function(q) {
				this.newQuestion.question = q;
				if (tinyMCE.get('question').getContent() == q) {
					console.log('hi');
				} else {
					tinyMCE.get('question').setContent(q);
				}
			},
			qTypeChange : function(type) {
				if (type == "MC") {
					this.showMC = true;
				}
				else{
					this.showMC = false;
					this.newQuestion.mc_hints = '';
				} 
				this.newQuestion.question_type = type;
			}
			
		},

	ready: function() {
		this.fetchQuestions();
		this.fetchDepartments();
	},

	methods: {
		fetchQuestions: function() {
			this.$http.get('/cms/questions?ajax=true', function(questions) {
				this.$set('questions', questions);
			});
		},
		fetchDepartments: function() {
			this.$http.get('/cms/departments', function(departments) {
				this.$set('departments', departments);
			});
		},

		createQuestion: function(){
			var question = this.newQuestion;
			this.newQuestion = {
				question: '',
				answer: '',
				tags: '',
				marks: 0,
				question_type: 0,
				department_id: 0
			};
			this.qTypeChange = 0;
			this.questionText = '';

			this.$http.post('/cms/questions', question, function(data) {
				toastr.success('Successfully created new Question.');
				this.fetchQuestions();
			});
		}, 

		getQuestions: function(){
			var	search = this.newSearch;
			this.$http.post('/cms/questions/get-questions', search, function(questions) {
				this.showPrint = true;
				this.$set('questions', questions);
			});
		}, 

		showQuestion: function(id) {
			this.edit = true
			this.$http.get('/cms/questions/' + id, function(data) {
				this.newQuestion.id = data.id;
				this.questionText = data.question;
				this.newQuestion.marks = data.marks;
				this.newQuestion.tags = data.tags;
				this.qTypeChange = data.question_type;
				this.newQuestion.department_id = data.department_id;
			});
		},

		editQuestion: function(id) {
			var question = this.newQuestion;
			this.newQuestion = {
				question: '',
				answer: '',
				tags: '',
				marks: 0,
				question_type: 0,
				department_id: 0
			};
			this.qTypeChange = 0;
			this.questionText = '';

			this.$http.patch('/cms/questions/' + id, question).success(function(response) {
				this.fetchQuestions();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelQuestionEdit: function() {
			this.newQuestion = {
				question: '',
				answer: '',
				tags: '',
				marks: 0,
				question_type: 0,
				department_id: 0
			};
			this.qTypeChange = 0;
			this.questionText = '';
			this.edit = false;
		},

		deleteQuestion: function(id) {
			if(confirm("Are you sure you want to delete this Question?")) {
				this.$http.delete('/cms/questions/'+ id).success(function(response) {
					this.fetchQuestions();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error);
				});
			}
		},
	}
});