new Vue({
	el: '#sale',

	data: {
		name: '',
		newSale: {
			id: '',
			product_id: '',
			vendor_id: '',
			quantity: '',
			date: ''
		},

		pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },

		offset: 4,

		edit: false,

		//For filters
        year: 0,
        month: 0,
        day: 0,
        staff: 0,
        state_id: 0,
        city_id: 0,
        vendor_id: 0
	},

	watch: {
		//Yearly filter
		// year_filter: function(year) {
		// 	if(year == 0) this.year = new Date().getFullYear();
		// 	else this.year = year;
			
		// 	//request url to get yearly invoices
		// 	var request = 'year='+ this.year;
		// 	//if month is exist, change request url to get dedicated month invoices
		// 	if(this.month > 0) request = request + '&month=' + this.month;
		// 	//if day is exist, change request url to dedicated day invoices
		// 	if(this.day > 0) request = request + '&day=' + this.day;
		// 	//if this.staff (id) is greater than default value
		// 	if(this.staff > 0) request = request + '&staff=' + this.staff;
		// 	if(this.state_id != 0) request = request + '&state_id='+this.state_id;	
		// 	if(this.city_id != 0) request = request + '&city_id='+this.city_id;
		// 	if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;
		// 	//pagination page
		// 	request = request + '&page=' + 1;
			
		// 	this.$http.get('/cms/api/sales?'+request,function(response) {
		// 		this.$set('sales', response.data);
		// 		this.$set('pagination', response.pagination);
		// 	});	
		// },

		// //Monthly filter
		// ym_filter: function(month) {
		// 	this.month = month;
		// 	//request url to get monthly invoices
		// 	var request = 'year='+ this.year + '&month=' + month;
		// 	//if month is 0, change request url to get yearly invoices
		// 	if(month == 0) request = 'year='+ this.year;
		// 	//if day is exist, change request url to get daily invoices
		// 	if(this.day > 0) request = request + '&day=' + this.day;
		// 	//if this.staff (id) is greater than default value
		// 	if(this.staff > 0) request = request + '&staff=' + this.staff;
		// 	if(this.state_id != 0) request = request + '&state_id='+this.state_id;	
		// 	if(this.city_id != 0) request = request + '&city_id='+this.city_id;
		// 	if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;
		// 	//pagination page
		// 	request = request + '&page=' + 1;

		// 	// this.$http.get('/cms/api/sales?'+this.ymFilter.year+'/'+month+'?page=' + this.pagination.current_page,function(response) {
		// 	// 	this.$set('sales', response.data);
		// 	// 	this.$set('pagination', response.pagination);
		// 	// });

		// 	this.$http.get('/cms/api/sales?'+request,function(response) {
		// 		this.$set('sales', response.data);
		// 		this.$set('pagination', response.pagination);
		// 	});	
		// },

		// //Daily Filter
		// ymd_filter: function(day) {
		// 	this.day = day;
		// 	//request url to get daily invoices
		// 	var request = 'year='+ this.year + '&month=' + this.month + '&day=' + day;
		// 	//if day is 0, change request url to get monthly invoices
		// 	if (day == 0) request = 'year='+ this.year + '&month=' + this.month;
		// 	//if this.staff (id) is greater than default value
		// 	if(this.staff > 0) request = request + '&staff=' + this.staff;
		// 	if(this.state_id != 0) request = request + '&state_id='+this.state_id;	
		// 	if(this.city_id != 0) request = request + '&city_id='+this.city_id;
		// 	if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;
		// 	//pagination page
		// 	request = request + '&page=' + 1;

		// 	this.$http.get('/cms/api/sales?'+request,function(response) {
		// 		this.$set('sales', response.data);
		// 		this.$set('pagination', response.pagination);
		// 	});	
		// },

		// //Filter with dedicated staff
		// staff_filter: function(staff) {
		// 	this.staff = staff;
		// 	var request = 'page=' + 1;
			
		// 	if(staff != 0) request = request + '&staff='+staff;
		// 	//if year is exist, change request url 
		// 	if(this.year > 0) request = request + '&year=' + this.year;
		// 	//if month is exist, change request url to get dedicated month invoices
		// 	if(this.month > 0) request = request + '&month=' + this.month;
		// 	//if day is exist, change request url to dedicated day invoices
		// 	if(this.day > 0) request = request + '&day=' + this.day;

		// 	if(this.state_id != 0) request = request + '&state_id='+this.state_id;	
		// 	if(this.city_id != 0) request = request + '&city_id='+this.city_id;
		// 	if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;
			
		// 	this.$http.get('/cms/api/sales?'+request,function(response) {
		// 		this.$set('sales', response.data);
		// 		this.$set('pagination', response.pagination);
		// 	});
		// },

		state_change : function(id) {
			this.state_id = id;
			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.$set('cities',cities);
			});
			// var request = 'page=' + 1;
			
			// if(id != 0) request = request + '&state_id='+id;

			// if(this.city_id != 0) request = request + '&city_id='+this.city_id;
			// if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;
			// //if year is exist, change request url 
			// if(this.year > 0) request = request + '&year=' + this.year;
			// //if month is exist, change request url to get dedicated month invoices
			// if(this.month > 0) request = request + '&month=' + this.month;
			// //if day is exist, change request url to dedicated day invoices
			// if(this.day > 0) request = request + '&day=' + this.day;

			// this.$http.get('/cms/api/sales?'+request,function(response) {
			// 	this.$set('sales', response.data);
			// 	this.$set('pagination', response.pagination);
			// });
		},

		city_change : function(id) {
			this.$http.get('/cms/api/vendors-in-city/' + id,function(vendors) {
				this.$set('vendors',vendors);
			});

			this.city_id = id;
			// var request = 'page=' + 1;
			
			// if(id != 0) request = request + '&city_id='+id;

			// if(this.state_id != 0) request = request + '&state_id='+this.state_id;
			// if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;
			// //if year is exist, change request url 
			// if(this.year > 0) request = request + '&year=' + this.year;
			// //if month is exist, change request url to get dedicated month invoices
			// if(this.month > 0) request = request + '&month=' + this.month;
			// //if day is exist, change request url to dedicated day invoices
			// if(this.day > 0) request = request + '&day=' + this.day;

			// this.$http.get('/cms/api/sales?'+request,function(response) {
			// 	this.$set('sales', response.data);
			// 	this.$set('pagination', response.pagination);
			// });
		},

		// vendor_change : function(id) {
		// 	this.vendor_id = id;
		// 	var request = 'page=' + 1;
			
		// 	if(id != 0) request = request + '&vendor_id='+id;

		// 	if(this.state_id != 0) request = request + '&state_id='+this.state_id;
		// 	if(this.city_id != 0) request = request + '&city_id='+this.city_id;
		// 	//if year is exist, change request url 
		// 	if(this.year > 0) request = request + '&year=' + this.year;
		// 	//if month is exist, change request url to get dedicated month invoices
		// 	if(this.month > 0) request = request + '&month=' + this.month;
		// 	//if day is exist, change request url to dedicated day invoices
		// 	if(this.day > 0) request = request + '&day=' + this.day;

		// 	this.$http.get('/cms/api/sales?'+request,function(response) {
		// 		this.$set('sales', response.data);
		// 		this.$set('pagination', response.pagination);
		// 	});
		// }
	},	

	computed: {
		errors: function() {
			if (! this.newSale['quantity']) return true;
			return false;
		},

		disable_filter: function() {
			if (this.year == 0 || this.month == 0 || this.state_id == 0) return true;
			return false;
		},

		//Disable month select input if year is not selected (Monthly Filter)
		noYearSelected: function() {
			if(this.year == 0) return true;
			return false;
		},
		//Disable day select input if month is not selected
		noMonthSelected: function() {
			if(this.month == 0) return true;
			return false;
		},

		isActived: function () {
            return this.pagination.current_page;
        },

        pagesNumber: function () {
            if (!this.pagination.to) {
                return [];
            }
            var from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }

            return pagesArray;
        }
	},

	ready: function() {
		this.fecthSales(this.pagination.current_page);
		this.fetchProducts();
		this.fetchStaffs();
		this.fetchVendors();
		this.fetchStates();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states', function(states) {
				this.$set('states', states);
			});
		},

		fecthSales: function(page) {
			//request url to get monthly invoices
			var request = '?page=' + page;
			
			if(this.staff > 0) request = request + '&staff='+this.staff;
			//if year is exist, change request url 
			if(this.year > 0) request = request + '&year=' + this.year;
			//if month is exist, change request url to get dedicated month invoices
			if(this.month > 0) request = request + '&month=' + this.month;
			//if day is exist, change request url to dedicated day invoices
			if(this.day > 0) request = request + '&day=' + this.day;

			this.$http.get('/cms/api/sales'+request, function(response) {
				this.$set('sales', response.data);
				this.$set('pagination', response.pagination);
			});
		},

		//Fetch staffs to filter invoices list
		fetchStaffs: function() {
			this.$http.get('/cms/office/staffs', function(staffs) {
				this.$set('staffs', staffs);
			});
		},

		changePage: function (page) {
            this.pagination.current_page = page;
            this.fecthSales(page);
        },

		fetchProducts: function() {
			this.$http.get('/cms/products', function(products) {
				this.$set('products', products);
			});
		},

		fetchVendors: function() {
			this.$http.get('/cms/vendors', function(vendors) {
				this.$set('vendors', vendors);
			});
		},

		createSale: function(){
			var sale = this.newSale;
			this.newSale = {
				product_id: '0',
				quantity: '',
				date: '',
				vendor_id: ''
			},
			this.$http.post('/cms/sales', sale, function(data) {
				toastr.success('Successfully created new sale.');
				this.fecthSales();
			});
		}, 

		showSale: function(id) {
			this.edit = true
			this.$http.get('/cms/sales/' + id, function(data) {
				this.newSale.id = data.id
				this.newSale.quantity = data.quantity
				this.newSale.product_id = data.product_id
				this.newSale.date = data.date
				this.newSale.vendor_id = data.vendor_id
			});
		},

		editSale: function(id) {
			var sale = this.newSale;
			this.newSale = {
				id: '',
				product_id: '0',
				quantity: '',
				date: '',
				vendor_id: ''
			};

			this.$http.patch('/cms/sales/' + id, sale).success(function(response) {
				this.fecthSales();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelSaleEdit: function() {
			this.newSale = {
				id: '',
				product_id: '',
				quantity: '',
				date: '',
				vendor_id: ''
			};
			this.edit = false;
		},

		deleteSale: function(id) {
			if(confirm("Are you sure you want to delete this Sale?")) {
				this.$http.delete('/cms/sales/'+ id).success(function(response) {
					this.fecthSales();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},

		getFilteredSales: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});

			var request = '?page=' + 1;
			
			if(this.staff != 0) request = request + '&staff=' + this.staff;
			if(this.state_id != 0) request = request + '&state_id='+this.state_id;	
			if(this.city_id != 0) request = request + '&city_id='+this.city_id;
			if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;
			if(this.year != 0) request = request + '&year=' + this.year;
			if(this.month != 0) request = request + '&month=' + this.month;
			if(this.day != 0) request = request + '&day=' + this.day;

			this.$http.get('/cms/api/sales'+request,function(response) {
				this.$set('sales', response.data);
				this.$set('money_total', response.money_total);
				this.$set('discount_total', response.discount_total);
				this.$set('dz_total', response.dz_total);
				this.$set('pcs_total', response.pcs_total);
				$.LoadingOverlay("hide");
				// this.$set('pagination', response.pagination);
			});	
		},

		numberWithCommas: function(x) {
		    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
	}
});