new Vue({
	el: '#department',

	data: {
		newDepartment: {
			id: '',
			name: '',
			short_key: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newDepartment['name']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchDepartments();
	},

	methods: {
		fetchDepartments: function() {
			this.$http.get('/cms/departments?ajax=true', function(departments) {
				this.$set('departments', departments);
			});
		},

		createDepartment: function(){
			var department = this.newDepartment;
			this.newDepartment = {
				name: '',
				short_key: ''
			},
			this.$http.post('/cms/departments', department, function(data) {
				toastr.success('Successfully created new Department.');
				this.fetchDepartments();
			});
		}, 

		showDepartment: function(id) {
			this.edit = true
			this.$http.get('/cms/departments/' + id, function(data) {
				this.newDepartment.id = data.id;
				this.newDepartment.name = data.name;
				this.newDepartment.short_key = data.short_key;
			});
		},

		editDepartment: function(id) {
			var department = this.newDepartment;
			this.newDepartment = {
				id: '',
				name: '',
				short_key: ''
			};

			this.$http.patch('/cms/departments/' + id, department).success(function(response) {
				this.fetchDepartments();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelDepartmentEdit: function() {
			this.newDepartment = {
				id: '',
				name: '',
				short_key: '',
			};
			this.edit = false;
		},

		deleteDepartment: function(id) {
			if(confirm("Are you sure you want to delete this Department?")) {
				this.$http.delete('/cms/departments/'+ id).success(function(response) {
					this.fetchDepartments();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});