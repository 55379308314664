new Vue({
	el: '#grade',

	data: {
		newGrade: {
			id: '',
			key: '',
			description: '',
			plan_id: '',
			invoice_percent: '',
			target_percent: '',
			min_amount: '',
			is_default: 0,
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newGrade['key'] || !this.newGrade['invoice_percent']|| !this.newGrade['target_percent'] || !this.newGrade['min_amount']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchGrades();
		this.fetchPlans();
	},

	methods: {
		fetchGrades: function() {
			this.$http.get('/cms/grades?ajax=true', function(grades) {
				this.$set('grades', grades);
			});
		},

		fetchPlans: function() {
			this.$http.get('/cms/plans', function(plans) {
				this.$set('plans', plans);
			});
		},

		createGrade: function(){
			var grade = this.newGrade;
			this.newGrade = {
				key: '',
				description: '',
				plan_id: '',
				invoice_percent: '',
				target_percent: '',
				min_amount: '',
				is_default: 0
			},
			this.$http.post('/cms/grades', grade, function(data) {
				toastr.success('Successfully created new grade.');
				this.fetchGrades();
			});
		}, 

		showGrade: function(id) {
			this.edit = true
			this.$http.get('/cms/grades/' + id, function(data) {
				this.newGrade.id = data.id;
				this.newGrade.key = data.key;
				this.newGrade.description = data.description;
				this.newGrade.plan_id = data.plan_id;
				this.newGrade.invoice_percent = data.invoice_percent;
				this.newGrade.target_percent = data.target_percent;
				this.newGrade.min_amount = data.min_amount;
				this.newGrade.is_default = data.is_default;
			});
		},

		editGrade: function(id) {
			var grade = this.newGrade;
			this.newGrade = {
				id: '',
				key: '',
				description: '',
				plan_id: '',
				invoice_percent: '',
				target_percent: '',
				min_amount: '',
				is_default: 0
			};

			this.$http.patch('/cms/grades/' + id, grade).success(function(response) {
				this.fetchGrades();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelGradeEdit: function() {
			this.newGrade = {
				id: '',
				key: '',
				description: '',
				plan_id: '',
				invoice_percent: '',
				target_percent: '',
				min_amount: '',
				is_default: 0
			};
			this.edit = false;
		},

		deleteGrade: function(id) {
			if(confirm("Are you sure you want to delete this Grade?")) {
				this.$http.delete('/cms/grades/'+ id).success(function(response) {
					this.fetchGrades();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});