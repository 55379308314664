new Vue({
	el: '#state',

	data: {
		newState: {
			id: '',
			name: '',
			slug: '',
			population: '',
			short_key: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newState['name'] || ! this.newState['slug']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchStates();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states?ajax=true', function(states) {
				this.$set('states', states);
			});
		},

		createState: function(){
			var state = this.newState;
			this.newState = {
				name: '',
				slug: '',
				population: '',
				short_key: ''
			},
			this.$http.post('/cms/states', state, function(data) {
				toastr.success('Successfully created new state.');
				this.fetchStates();
			});
		}, 

		showState: function(id) {
			this.edit = true
			this.$http.get('/cms/states/' + id, function(data) {
				this.newState.id = data.id;
				this.newState.name = data.name;
				this.newState.population = data.population;
				this.newState.short_key = data.short_key;
				this.newState.slug = data.slug;
			});
		},

		editState: function(id) {
			var state = this.newState;
			this.newState = {
				id: '',
				name: '',
				population: ''
			};

			this.$http.patch('/cms/states/' + id, state).success(function(response) {
				this.fetchStates();
				this.edit = false; 
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelStateEdit: function() {
			this.newState = {
				id: '',
				name: '',
				population: '',
			};
			this.edit = false;
		},

		deleteState: function(id) {
			if(confirm("Are you sure you want to delete this State?")) {
				this.$http.delete('/cms/states/'+ id).success(function(response) {
					this.fetchStates();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});