new Vue({
	el: '#main_report',

	data: {
		newSearch: {
			state_id: '',
			city_id: '',
			year: '',
			season: '',
			plan_id: '',
			active_only: ''
		},

		newTarget: {
			state_id: '',
			city_id: '',
			year: '',
			season: '',
			plan_id: '',
			target: '',
			active_only: '',
			is_target: true
		},

		state_change: '0',
		chart_count: 0,
		target_is_calculated: 0
	},

	watch: {
		state_change : function(id) {
			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.newSearch.state_id = id;
				this.newTarget.state_id = id;
				this.$set('cities',cities);
				// this.state_change = 0;
			});
		}
	},

	computed: {
		got_results: function() {
			if (this.final_results.length == 0) return false;
			return true;
		},

		got_targets: function() {
			if (this.final_result.length == 0) return false;
			return true;
		},

		errors: function() {
			if (! this.newSearch['state_id'] || ! this.newSearch['year'] || ! this.newSearch['plan_id']) return true;
			return false;
		},

		targetErrors: function() {
			if (! this.newTarget['state_id'] || ! this.newTarget['year'] || ! this.newTarget['plan_id'] || ! this.newTarget['target']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchStates();
		this.fetchPlans();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states', function(states) {
				this.$set('states', states);
			});
		},

		fetchPlans: function() {
			this.$http.get('/cms/plans', function(plans) {
				this.$set('plans', plans);
			});
		},

		getReport: function(){
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			var report = this.newSearch;
			this.$http.post('/cms/reports/search', report, function(data) {
				this.$set('final_results', data);
				$.LoadingOverlay("hide");

				var data = {
				    labels: this.final_results.chart.months,
				    datasets: this.final_results.chart.data
				};

				var options = {
					scales: {
			            xAxes: [{
							stacked: true
			            }],
			            yAxes: [{
							stacked: true
			            }]
			        }
				};
				
				var chartContainer = $('#chart-container');

				chartContainer.html('');

				chartContainer.append('<canvas id="report-chart-' + this.chart_count + '" height="200px"></canvas>');

				var report = document.getElementById('report-chart-' + this.chart_count).getContext('2d');
			    
			    new Chart(report, {
				    type: 'bar',
				    data: data,
				    options: options
				});

				this.chart_count++;
			});
		}, 

		getTarget: function(){
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.get('/cms/api/target-is-calculated?state_id='+this.newTarget['state_id']+'&year='+this.newTarget['year']+'&plan_id='+this.newTarget['plan_id']+'&season='+this.newTarget['season'], function(response) {
					this.target_is_calculated = response;
				});
			var targets = this.newTarget;
			this.$http.post('/cms/target', targets, function(data) {
				this.$set('final_result', data);
				$.LoadingOverlay("hide");
			});
		},

		saveTarget: function(month = null, vendor = null){
			var that = this;
			var route = '';
			if(month != null && vendor == null) {
				route = '/cms/target/save?month='+month;
			} else if(month == null && vendor != null){
				route = '/cms/target/save?vendor='+vendor;
			} else {
				route = '/cms/target/save';
			}

			swal({
                title: "<span style='color:#999'>ေသခ်ာပါသလား<span>",
                text: "<span style='font-size:14px;'>သိမ္းဆည္းလိုက္ပါက ကုန္သည္တစ္ေယာက္ခ်င္းစီနဲ႔ သြားေရာက္ခ်ိတ္ဆက္မွာျဖစ္ၿပီး၊ ကုန္သည္ႏွင့္ သေဘာတူမည့္ target ကို ျပန္လည္ျပင္ဆင္ႏိုင္မွာျဖစ္ပါတယ္။</span>",
                html: true,
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                cancelButtonText: "No",
                closeOnConfirm: false,
                closeOnCancel: true,
                showLoaderOnConfirm: true
            }, 
            function(isConfirm) {
            	if(isConfirm) {
					that.$http.get(route).success(function(response) {
						swal({
		                    title: 'Saved!',
		                    text: "<span style='font-size:14px;'>ကုန္သည္ တစ္ဦးခ်င္းစီအတြက္ target ကိုသိမ္းလိုက္ပါၿပီ။</span>",
		                    html:true,
		                    type: 'success',
		                    confirmButtonText: 'ေက်းဇူးပါ',
			                closeOnConfirm: false,
			                closeOnCancel: false
		                  });
					}).error(function(error) {
						swal(
		                    'Error !',
		                    error.message,
		                    'error'
		                  );
					});
            	}
            });
		}
	}
});