"use: strict";

new Vue({
	el: '#detail_vendor',

	data: {
		newSearch: {
			year: '',
			season: '',
			plan_id: ''
		},
		invoices: [],
		vendor_id: '',

		edit: false
	},


	computed: {
		got_results: function() {
			if (this.invoices[0].length == 0) return false;
			return true;
		},
	},

	ready: function() {
		this.fetchVendorInvoices();
		this.fetchPlans();
	},

	methods: {
		fetchVendorInvoices: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			var id = this.vendor_id;
			this.$http.get('/cms/api/vendor-invoices/'+ id, function(invoices) {
				this.invoices = invoices.invoice_id;
				this.$set('invoices',invoices);
				$.LoadingOverlay("hide");
			})
		},

		fetchPlans: function() {
			this.$http.get('/cms/plans', function(plans) {
				this.$set('plans', plans);
			});
		},

		getReport: function(){
			var id = this.vendor_id;
			var report = this.newSearch;
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			this.$http.post('/cms/vendor/reports/search/'+id, report, function(reports) {
				// console.log(reports);
				this.$set('final_results',reports);
				$.LoadingOverlay("hide");
			});
		}, 
	}
});