new Vue({
	el: '#type',

	data: {
		newType: {
			id: '',
			key: '',
			multiply_factor: '',
			parent_id: '',
			is_parent: '',
			remark: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newType['key'] || !this.newType['multiply_factor']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchTypes();
		this.fetchParentTypes();
	},

	methods: {
		fetchTypes: function() {
			this.$http.get('/cms/types?ajax=true', function(types) {
				this.$set('types', types);
			});
		},

		fetchParentTypes: function() {
			this.$http.get('/cms/api/get-parent-types', function(types) {
				this.$set('parent_types', types);
			});	
		},

		createType: function(){
			var type = this.newType;

			if(type['is_parent'] == true) 
			{
				type['parent_id'] = 0;
			}

			this.newType = {
				key: '',
				multiply_factor: '',
				parent_id: '',
				remark: '',
				is_parent: ''
			},
			this.$http.post('/cms/types', type, function(data) {
				toastr.success('Successfully created new type.');
				this.fetchTypes();
			});
		}, 

		showType: function(id) {
			this.edit = true
			this.$http.get('/cms/types/' + id, function(data) {
				this.newType.id = data.id;
				this.newType.key = data.key;
				this.newType.parent_id = data.parent_id;
				this.newType.multiply_factor = data.multiply_factor;
				this.newType.remark = data.remark;

				if(data.parent_id == 0) {
					this.newType.is_parent = true;
				}
			});
		},

		editType: function(id) {
			var type = this.newType;
			
			if(type['is_parent'] == true) 
			{
				type['parent_id'] = 0;
			}
			
			this.newType = {
				key: '',
				multiply_factor: '',
				parent_id: '',
				remark: '',
				is_parent: ''
			},

			this.$http.patch('/cms/types/' + id, type).success(function(response) {
				this.fetchTypes();
				this.edit = false;
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelTypeEdit: function() {
			this.newType = {
				id: '',
				key: '',
				multiply_factor: '',
				remark: '',
				is_parent: '',
				parent_id: ''
			};
			this.edit = false;
		},

		deleteType: function(id) {
			if(confirm("Are you sure you want to delete this Type?")) {
				this.$http.delete('/cms/types/'+ id).success(function(response) {
					this.fetchTypes();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});