new Vue({
	el: '#bank',

	data: {
		newBank: {
			id: '',
			statement_no: '',
			statement_amount: '',
			vendor_id : '',
			date: '',
			type: 'bank',
			charges: [
				{
					id: '',
					type:"",
		          	price:"",
		          	date:""
	          	}
			]
		},
		currentStatement: {},
		amount: '',
		polo: '',
		edit: false,
		cash_history_statement: '',

		bank_pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },

		offset: 4
	},

	computed: {
		errors: function() {
			// if (! this.newBank['vendor_id']) return true;
			return false;
		},

		isActived: function () {
            return this.bank_pagination.current_page;
        }, 

        bankPagesNumber: function () {
            if (!this.bank_pagination.to) {
                return [];
            }
            var from = this.bank_pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.bank_pagination.last_page) {
                to = this.bank_pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }

            return pagesArray;
        }
	},

	watch: {
		vendor_filter: function(id) {
			if (id != 0) {
				this.$http.get('/cms/api/allbanks-by-vendor/' + id,function(banks) {
					this.$set('banks',banks);
				});	
			}
		}
	},

	ready: function() {
		this.fetchVendors();
		this.fetchBanks(this.bank_pagination.current_page);
	},

	methods: {
		fetchBanks: function(page) {
			this.$http.get('/cms/banks?page='+page, function(response) {
				//this.$set('banks', banks);
				this.$set('banks', response.data);
				this.$set('bank_pagination', response.pagination);
			});
		},

		changePage: function (page) {
            this.bank_pagination.current_page = page;
            this.fetchBanks(page);
        },

		fetchVendors: function() {
			this.$http.get('/cms/api/fetch-vendors-by-staff', function(vendors) {
				this.$set('vendors',vendors);
			});
		},

		createBank: function(){
			var bank = this.newBank;
			console.log('aa');
			console.log(bank);
			this.$http.post('/cms/banks', bank, function(data) {
				this.fetchBanks();
				toastr.success('Successfully created new Bank Statement.');
				// this.addedCustomer = data;
				$('#add_bank').modal('hide');
				this.clearBankInput();
				var input = $('#reload');
				input.val('1');
				input.trigger('change');
			});
		},

		showBank: function(id) {
			this.edit = true
			this.$http.get('/cms/banks/' + id, function(data) {
				this.newBank.id = data.id;
				this.newBank.statement_no = data.statement_no;
				this.newBank.date = data.date;
				this.newBank.type = data.type;
				this.newBank.statement_amount = parseFloat(data.statement_amount);
				this.newBank.vendor_id = data.vendor_id;
				this.newBank.charges = data.charges;
			});
		},

		editBank: function(id) {
			var bank = this.newBank;
			this.clearBankInput();
			this.$http.patch('/cms/banks/' + id, bank).success(function(response) {
				this.fetchBanks();
				this.edit = false;
				toastr.success('Successfully updated!');
				this.clearBankInput();
			}).error(function(error) {
				toastr.success(error)
			});
		},

		addNewCharge: function(){
	      var newBank = {
	        type:"",
	        price:"",
	        date:""
	      };
	      this.newBank.charges.push(newBank);
	    },

		editPoLo: function(statement) {
			console.log('hello');
			this.currentStatement = statement;
			$('#edit_amount').modal();
		},

		submitPoLo: function() {
			if (this.polo == 'l') {
				var amount = this.amount;
			}
			else var amount = -this.amount;

			

			if(confirm("Are You Sure?")) {
				$('#edit_amount').modal('hide');
				var data = {
					statement_amount: this.currentStatement.statement_amount,
					vendor_id : this.currentStatement.vendor_id,
					date: this.currentStatement.date,
					type: this.currentStatement.type,
					remaining_amount: this.currentStatement.remaining_amount,
					statement_no : this.currentStatement.statement_no,
					status : this.currentStatement.status,
					user_id: this.currentStatement.user_id,
					amount : amount,
				}


				this.$http.patch('/cms/api/update-polo/' + this.currentStatement.id, data).success(function(response) {
					this.amount = '';
					this.polo = '';
					this.currentStatement = {};
					// this.fetchBanks();
					toastr.success('Success!');
				}).error(function(error) {
					toastr.error(error);
				});
			}
		},

		cancelBankEdit: function() {
			this.clearBankInput();
			this.edit = false;
		},

		deleteBank: function(id) {
			if(confirm("Are you sure you want to delete this Statement?")) {
				this.$http.delete('/cms/banks/'+ id).success(function(response) {
					this.fetchBanks();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},

		showBankHistory: function(id) {
			$('#bank_history').modal('show');
			this.$http.get('/cms/banks/history/' + id, function(data) {
				this.cash_history_statement = data;
			});	
		},

		clearBankInput: function() {
			this.newBank = {
			id: '',
			statement_no: '',
			statement_amount: '',
			date: '',
			type: 'bank',
			vendor_id: '',
			charges: [
				{
					id: '',
					type:"",
		          	price:"",
		          	date:""
	          	}
			]

			};
		}
	}
});

new Vue({
	el: '#old-bank',

	data: {
		newBank: {
			id: '',
			statement_no: '',
			statement_amount: '',
			vendor_id : '',
			date: '',
			type: 'bank',
			charges: [
				{
					id: '',
					type:"",
		          	price:"",
		          	date:""
	          	}
			]
		},
		currentStatement: {},
		amount: '',
		polo: '',
		edit: false,
		cash_history_statement: '',

		old_bank_pagination: {
            total: 0,
            per_page: 7,
            from: 1,
            to: 0,
            current_page: 1
        },

		offset: 4
	},

	computed: {
		errors: function() {
			// if (! this.newBank['vendor_id']) return true;
			return false;
		},

		isActived: function () {
            return this.old_bank_pagination.current_page;
        }, 

        oldPagesNumber: function () {
            if (!this.old_bank_pagination.to) {
                return [];
            }
            var from = this.old_bank_pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            var to = from + (this.offset * 2);
            if (to >= this.old_bank_pagination.last_page) {
                to = this.old_bank_pagination.last_page;
            }
            var pagesArray = [];
            while (from <= to) {
                pagesArray.push(from);
                from++;
            }

            return pagesArray;
        }
	},

	watch: {
		vendor_filter: function(id) {
			if (id != 0) {
				this.$http.get('/cms/api/banks/old-by-vendor/' + id,function(banks) {
					this.$set('oldbanks',banks);
				});	
			}
		}
	},

	ready: function() {
		this.fetchVendorsOld();
		this.fetchOldBanks(this.old_bank_pagination.current_page);
	},

	methods: {
		fetchOldBanks: function(page) {
			this.$http.get('/cms/api/banks/old?page='+page, function(response) {
				//this.$set('oldbanks', banks);
				this.$set('oldbanks', response.data);
				this.$set('old_bank_pagination', response.pagination);
			});
		},

		changeOldBankPage: function (page) {
            this.old_bank_pagination.current_page = page;
            this.fetchOldBanks(page);
        },

		fetchVendorsOld: function() {
			this.$http.get('/cms/api/fetch-vendors-by-staff', function(vendors) {
				this.$set('vendorsold',vendors);
			});
		},

		deleteBank: function(id) {
			if(confirm("Are you sure you want to delete this Statement?")) {
				this.$http.delete('/cms/banks/'+ id).success(function(response) {
					this.fetchBanks();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},

		showBankHistoryOld: function(id) {
			$('#bank_history_for_old').modal('show');
			this.$http.get('/cms/banks/history/' + id, function(data) {
				this.cash_history_statement = data;
			});	
		},
	}
});

new Vue({
	el: '#filter',

	data: {
		from_date: '',
		to_date: '',
		state_id: 0,
		city_id: 0,
		vendor_id: 0
	},

	computed: {
		disable_filter: function() {
			if (this.state_id == 0) return true;
			return false;
		}
	},

	watch: {
		state_change : function(id) {
			this.state_id = id;
			this.$http.get('/cms/api/cities-in-state?state_id=' + id,function(cities) {
				this.$set('cities',cities);
			});
		},

		city_change : function(id) {
			this.$http.get('/cms/api/vendors-in-city/' + id,function(vendors) {
				this.$set('filter_vendors',vendors);
			});

			this.city_id = id;
		}
	},

	ready: function() {
		this.fetchStates();
	},

	methods: {
		fetchStates: function() {
			this.$http.get('/cms/states', function(states) {
				this.$set('states', states);
			});
		},

		getFilteredStatements: function() {
			$.LoadingOverlay("show", {
				image       : "",
    			fontawesome : "fa fa-spinner fa-spin"
			});
			
			var request = '?page=1';

			if(this.from_date != '') request = request + '&from=' + this.from_date;
			if(this.to_date != '') request = request + '&to='+this.to_date;	
			if(this.state_id != 0) request = request + '&state_id='+this.state_id;
			if(this.city_id != 0) request = request + '&city_id='+this.city_id;
			if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;
			
			this.$http.get('/cms/api/banks/filter'+request, function(response) {
				this.$set('filter_statements', response.banks);
				this.$set('income_total', response.income_total);
				this.$set('charges_total', response.charges_total);
				$.LoadingOverlay("hide");
			});
		},

		downloadExcel: function() {
			var request = '?page=1';

			if(this.from_date != '') request = request + '&from=' + this.from_date;
			if(this.to_date != '') request = request + '&to='+this.to_date;	
			if(this.state_id != 0) request = request + '&state_id='+this.state_id;
			if(this.city_id != 0) request = request + '&city_id='+this.city_id;
			if(this.vendor_id != 0) request = request + '&vendor_id='+this.vendor_id;

			window.location.href = '/cms/api/banks/export'+request;
		},

		deleteFilterBank: function(id) {
			if(confirm("Are you sure you want to delete this Statement?")) {
				this.$http.delete('/cms/banks/'+ id).success(function(response) {
					this.getFilteredStatements();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		}
	}
});