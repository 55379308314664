new Vue({
	el: '#carton',

	data: {
		newCarton: {
			id: '',
			name: '',
			dozen_count: '',
			weight: '',
			height: '',
			width: '',
			length: ''
		},

		edit: false
	},

	computed: {
		errors: function() {
			if (! this.newCarton['name'] || ! this.newCarton['dozen_count']) return true;
			return false;
		}
	},

	ready: function() {
		this.fetchCartons();
	},

	methods: {
		fetchCartons: function() {
			this.$http.get('/cms/export/cartons?ajax=true', function(cartons) {
				this.$set('cartons', cartons);
			});
		},

		createCarton: function(){
			var carton = this.newCarton;
			this.newCarton = {
				id: '',
				name: '',
				dozen_count: '',
				weight: '',
				height: '',
				width: '',
				length: ''
			};
			this.$http.post('/cms/export/cartons', carton, function(data) {
				toastr.success('Successfully created new Carton.');
				this.fetchCartons();
			});
		}, 

		showCarton: function(id) {
			this.edit = true
			this.$http.get('/cms/export/cartons/' + id, function(data) {
				this.newCarton.id = data.id;
				this.newCarton.name = data.name;
				this.newCarton.dozen_count = data.dozen_count;
				this.newCarton.weight = data.weight;
				this.newCarton.height = data.height;
				this.newCarton.width = data.width;
				this.newCarton.length = data.length;
			});
		},

		editCarton: function(id) {
			var carton = this.newCarton;
			this.newCarton = {
				id: '',
				name: '',
				dozen_count: '',
				weight: '',
				height: '',
				width: '',
				length: ''
			};

			this.$http.patch('/cms/export/cartons/' + id, carton).success(function(response) {
				this.fetchCartons();
				this.edit = false; 
				toastr.success('Successfully updated!');
			}).error(function(error) {
				toastr.success(error);
			});
		},

		cancelCartonEdit: function() {
			this.newCarton = {
				id: '',
				name: '',
				dozen_count: '',
				weight: '',
				height: '',
				width: '',
				length: ''
			};
			this.edit = false;
		},

		deleteCarton: function(id) {
			if(confirm("Are you sure you want to delete this Carton?")) {
				this.$http.delete('/cms/export/cartons/'+ id).success(function(response) {
					this.fetchCartons();
					toastr.success('Successfully deleted!')
				}).error(function(error) {
					toastr.error(error)
				});
			}
		},
	}
});